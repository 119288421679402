import React, {useState, useCallback, useRef} from 'react'
import {json, Link, useLocation, useParams} from 'react-router-dom'
import Flickity from 'react-flickity-component'
import '../css/flickity.min.css'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import HomeBanner from '../component/home-banner'
import VideoItem from '../component/video-item'
import PopularVideo from '../component/popular-video'
import TrendingHilite from '../component/trending-hilite'
import SvgArrow from '../component/svg-arrow'
import Hr from '../component/hr'
import Quiz from '../component/quiz'
import ArticleList from '../component/article-list'
import ForumItem from '../component/forum-item'
import HomePromo from '../component/home-promo'
import InstagramCornerItem from '../component/instagram-corner-item'
import PopupLogin from '../component/popup-login'
import {fetchHome, fetchNotif} from "../api_fetch";
import instafeed from 'instafeed.js'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

import { InstagramEmbed } from 'react-social-media-embed'

const Home = () => {
  const cref = useRef();

  const handleClickPopup = () => {
    cref.current.handlePopupLogin()
  }

  const location = useLocation();

  const [trending, setTrending] = useState();
  const [popular, setPopular] = useState();
  const [highTrending, setHighTrending] = useState();
  const [highPop, setHighPopular] = useState();
  const [highPremium, setHighPremium] = useState();
  const [sharing, setSharing] = useState();
  const [premium, setPremium] = useState();
  const [category, setCategory] = useState();
  const [banner, setBanner] = useState();
  const [topBanner, setTop] = useState();
  const [isLoading, setLoading] = useState(true);

  const [newNotif, setNewNotif] = useState(false);

  const [isBlur, setBlur] = useState(false)
  const [jsonIG,setJSON] = useState();

  const togglePopupLogin = () => {
    setBlur(!isBlur)
  }

  const optionsHomeCollab = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: false
  }
  const instagramCorner = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
  }


  // todo instagram ximpay store
  const instagramPost =  () => {
    
    /* 
      1. login ke FB Developer
      2. tab instagram basic display
      3. user token generator
      4. generate Token dengan email IG iamprettysquad@gmail.com
      5. copas dibawah
    */
    let result;

    var feed = new instafeed({
      accessToken: "IGQWRQUmVJNXlkWHRIUUJDaG44ZA0hGcVZADUlpNZAkVLUG5fLW5wSGJFZAllpOXJCZAEpxQlRzR2FIWDI0MTk5X0FMdTM1bWFKZA204UThKU0dtV3dvWDc3bWJ1ekZADbW9nV2xsT3hjSnZAKZA2hFWlUzWnU1SDAtYVUxYlUZD",
      template: '<a href="{{link}}"><img src="{{image}}" /></a>',
      target: 'instafeed',
      limit: 10,
      success: function(data){
        result=data.data;
      },
      after: function (data) {
        processIG(result)
      },
      
    });
    feed.run();
  }

  const processIG = (data) =>{
    let arr = data

    let currentArr = [];

    let obj = JSON.parse(JSON.stringify(arr));

    for(let i in obj){
      if(currentArr.length<3 && obj[i].media_type!=='VIDEO')
        currentArr.push(obj[i])
    }

    setJSON(currentArr);
  }  

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }


  React.useEffect(() => {
    init()
  }, []);
  

  const init = () => {
    fetchHome((data) => {

      ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
      setCategory(data.category)
      setBanner(data.banner)
      setTop(data.top_banner)
      setHighTrending(data.trending.slice(0,1))
      setTrending(data.trending.slice(1,5))

      setPopular(data.popular.listArticle.slice(1,5))
      setHighPopular(data.popular.listArticle.slice(0,1))

      setSharing(data.sharing)

      setHighPremium(data.premium.slice(0,1))
      setPremium(data.premium.slice(1,5))

      setLoading(false);
      // instagramPost();

      try{
        if(location.state.needLogin){
          handleClickPopup()
          // prevent popup occur everytime reload page
          window.history.replaceState({}, document.title)          
        }
      }catch(e){
      
      }
    });

    checkNotif();

    
  }
  

  if(isLoading)
    return (
    <div className="relative">
      {/* <PopupLogin ref={cref}/> */}
      <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
    </div>
    )


  return (
    <div className="relative">
      <PopupLogin ref={cref}/>
      
      <div className="top absolute top-0 w-full"></div>
      <div className={"wrapper relative z-10 " + (isBlur === true ? "blury" : "")}>
      
        <Header nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">

            <HomeBanner category={category} top={topBanner}/>
           
              <section className="container pt-4 pb-4 lg:pt-12">
                <h3 className="font-bold mb-5 lg:mb-6">Trending Now!</h3>
                <div className="block sm:flex sm:gap-11 px-5 md:px-0">
                  <span className="trending-left w-full">
                    <TrendingHilite cat={highTrending[0].categories} isfree={highTrending[0].flag_free} thumb={highTrending[0].thumb_url} id={highTrending[0].video_id} total_like={highTrending[0].total_like} desc={highTrending[0].description} title={highTrending[0].title} time={highTrending[0].dateStr} duration={highTrending[0].durationStr} type="premium"/>
                  </span>
                  <span className="trending-right w-full grid grid-cols-1 sm:grid-cols-2 gap-x-11 sm:gap-y-8 gap-7">

                    {trending?.map((trend) => (
                            <VideoItem key={"tr"+trend.video_id} cat={trend.categories} isfree={trend.flag_free} title={trend.title} id={trend.video_id} time={trend.dateStr} duration={trend.durationStr} type="premium" thumb={trend.thumb_url}/>
                    ))}
                  </span>
                </div>
                <Link to="/video-category?cid=5"><button className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">More Video<SvgArrow/></button></Link>
              </section>
           
             <Hr/>
           
            
              <section className="pt-8 pb-4 lg:py-20 relative">
                <div className="container">
                <h3 className="font-bold mb-5 lg:mb-14 flex items-center leading-tight">Creator<div className="h3-icon ml-3"></div></h3>
                <div className="px-5 md:px-0">
                  <PopularVideo isfree={highPremium[0].flag_free} cat={highPremium[0].categories} type="premium" total_like={highPremium[0].total_like} time={highPremium[0].dateStr} duration={highPremium[0].durationStr} id={highPremium[0].video_id} title={highPremium[0].title} content={highPremium[0].description} thumb={highPremium[0].thumb_url}/>
                  <div className="hidden lg:grid grid-cols-3 gap-x-11 gap-y-8 mt-7 sm:mt-14">
                    {premium?.map((p) => (
                            <VideoItem key={'pc'+p.video_id} cat={p.categories} isfree={p.flag_free} title={p.title} id={p.video_id} time={p.dateStr} duration={p.durationStr} type="premium" thumb={p.thumb_url}/>
                    ))}
                  </div>
                  <Flickity className="carousel deep-carousel carousel-collab block lg:hidden" elementType="div" options={optionsHomeCollab}>
                    
                    {premium?.map((p) => (                          
                      <div key={'md' + p.video_id} className="carousel-cell">
                        <VideoItem key={'m'+p.video_id} isfree={p.flag_free} cat={p.categories} title={p.title} id={p.video_id} time={p.dateStr} duration={p.durationStr} type="premium" thumb={p.thumb_url}/>
                      </div>
                    ))}
                    <div className="last-slide"></div>
                  </Flickity>
                </div>
                <Link to="/video-program"><button className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">More Video<SvgArrow/></button></Link>
                </div>
                <div className="grad-btm absolute bottom-0 left-0 w-full"></div>
              </section>
            <HomePromo banner={banner} type={'web'}/>
            <section className="relative pt-8 pb-4 lg:pt-28 lg:pb-12">
              <div className="bg-article absolute left-0 hidden lg:block"></div>
              <div className="article container relative">
                <h2 className="font-semibold xs:mb-5">Beauty Articles</h2>
                <h4 className="font-semibold hidden md:block mt-3">Popular Articles!</h4>
                <div className="article-content flex gap-12 lg:gap-40 mt-6">
                  <span className="w-3/5 article-left hidden md:block">
                    <Link to={"/article-detail/"+highPop[0].article_id}>
                      <div className="article-left-inner ml-auto">
                          <div className="article-img relative">
                            <img loading="lazy" src={highPop[0].image_url?highPop[0].image_url:"https://s.tykcdn.com/assets/pretty_squad/img/img_banner_program.webp"} className="absolute h-full object-cover" alt="" />
                            <div className="absolute bottom-0 w-full"></div>
                          </div>
                      
                          <h3 className="font-semibold leading-tight mt-4">{highPop[0].title}</h3>
                        
                          <div className="article-time mt-2">{highPop[0].dateStr}</div>
                      </div>
                    </Link>
                  </span>
                  <span className="w-full lg:w-2/5 px-5 md:px-0">
                    <ul>
                      {popular?.map((p) => (
                          
                          <ArticleList key={'pop'+p.article_id} title={p.title} id={p.article_id} content={p.content} image={p.thumb_url} />
                      ))}
                    </ul>
                  </span>
                </div>
              </div>
              <Link to="/article/1"><button className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">More Article<SvgArrow/></button></Link>
            </section>
            <Hr/>
            <Quiz/>
            <section className="sharing-corner container pt-8 pb-4 lg:py-16">
              <h2 className="font-semibold mb-5 lg:mb-16">Sharing Corner</h2>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-7 lg:gap-y-12 px-5 md:px-0">
                {sharing?.map((s) => (
                    <ForumItem key={'forum'+s.forum_id} img={s.image_url} title={s.title} id={s.forum_id} name={s.name} time={s.dateStr} tlike={s.total_like} tcomment={s.total_comment} comment={s.description} />
                ))}
              </div>
              <Link to="/forum-category/1"><button className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">Join Forum<SvgArrow/></button></Link>
            </section>
            <section className="py-14 px-4 lg:pt-0 lg:pb-20" >
              <div className="instagram-corner container-cst">
                <h2 className="font-semibold mb-5 lg:mb-12">Instagram Corner</h2>
                <div className="hidden md:grid grid-cols-3 gap-12">

                  {/* {jsonIG?.map((json) => (
                      <InstagramCornerItem data={json}/>
                  ))} */}

                  <InstagramEmbed
                  url="https://www.instagram.com/p/C3kOfJbxWdm/"
                  />
                  <InstagramEmbed
                  url="https://www.instagram.com/reel/C3XHgaBRPpM/"
                  />
                  <InstagramEmbed
                  url="https://www.instagram.com/p/C3R7Xdmx_M4/"
                  />

  
                </div>
                <Flickity className="carousel carousel-instagram-corner block md:hidden" elementType="div" options={instagramCorner}>
                  
                  {/* {jsonIG?.map((json) => (
                    <div className="carousel-cell">
                      <InstagramCornerItem data={json}/>
                    </div>
                  ))} */}
                <div className="carousel-cell" style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed
                    url="https://www.instagram.com/p/C3kOfJbxWdm/"
                    />
                </div>
                <div className="carousel-cell" style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed
                     url="https://www.instagram.com/reel/C3XHgaBRPpM/"
                    />
                </div>   
                <div className="carousel-cell" style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed
                    url="https://www.instagram.com/p/C3R7Xdmx_M4/"
                    />
                </div>      
                  <div className="last-slide"></div>
                </Flickity>
                <Link to="https://www.instagram.com/prettysquad_id"><button className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">Go to Social Media<SvgArrow/></button></Link>
              </div>
            </section>
            <div id="instafeed" className='hidden'></div>
          </div>
          <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default Home