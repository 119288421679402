import React from 'react'
import {Link} from 'react-router-dom'
import Tags from '../component/tags'

const VideoPlayItem = (props) => {

  let custom;
  let id= props.id;

  custom = "/video-play/" + id;

  return (
      <div className="video-play-item flex items-center gap-2 p-1.5">
        <span className="w-1/2">
          <Link to={custom}><div className="video-play-item-img relative"><img src={props.thumb} className="absolute w-full h-full object-cover" alt="" /></div></Link>
        </span>
        <span className="w-1/2 video-play-item-r">
          <Tags cat={props.cat}/>
          <Link to={custom}>
            <div className="video-play-item-r-sub line-clamp-3">{props.title}</div>
          </Link>
          <div className="video-play-item-r-btm flex items-end justify-between">
            <span><b>{props.duration}</b></span>
            <span><button className="video-item-share"></button></span>
          </div>
        </span>
      </div>
  )
}

export default VideoPlayItem