import React, {useState} from 'react'
import Header, { isLogin } from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import ForumItem from '../component/forum-item'
import UserImg from '../component/user-img'
import UserImgName from '../component/user-img-name'
import Post from '../component/post'
import Comment from '../component/comment'
import { fetchForumDetail, fetchForumReply, fetchForumTrending, fetchNotif } from '../api_fetch'
import { useParams, useNavigate } from "react-router-dom";
import { useHookstate } from '@hookstate/core';
import { AVATAR, NAME } from '../App';
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

export default function ForumDetail() {

  const navigate = useNavigate();

  const { id } = useParams();
  const { index } = useParams();

  const [isLoading, setLoading] = useState(true);

  const [detail,setDetail] = useState();
  const [comments,setComments] = useState();
  const [trending, setTrending] = useState();

  const [commentReply, setCommentReply] = useState();

  const [refresh, setRefresh] = useState(false);

  const [isScroll, setIsScroll] = useState(true);

  const [newNotif, setNewNotif] = useState(false);

  const myAvatar = useHookstate(AVATAR);
  const myName = useHookstate(NAME);

  let arr = [];
  const [crumb, setCrumb] = useState([])


  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    fetchForumDetail(id, (data)=>{
      setDetail(data.detail)
      setComments(data.comments)      

      fetchForumTrending((data)=>{
        setTrending(data)

        setLoading(false);

        if(index && isScroll){
          handleClickScroll()
        }

      })
    });

    checkNotif();

  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  const needRefresh = () =>{
    setRefresh(refresh+1)
  }

  const handleComment = (e) =>{
    setCommentReply(e.target.value)
  }

  const replyForum = (forumId) => {
    let param = {
      forum_id:forumId,
      comment:commentReply
    }

    fetchForumReply(param,(data)=>{

      setCommentReply('')

      if(localStorage.getItem("sessid")===null){
        navigate('/', {
          state: {
            needLogin: true
          }
        });
      }else{      
        fetchForumDetail(id, (result)=>{
          setDetail(result.detail)
          setComments(result.comments)  

          setRefresh(refresh+1);
        });
      }
    })
  }

  const handleClickScroll = () => {
    const element = document.getElementById(index);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      setIsScroll(false);
    }
  };

  
  React.useEffect(() => {

    arr=[]
    arr.push(["/","Home"])
    arr.push(["/forum-category/1","Forums"])
    arr.push(["/forum-detail/"+id,"Topic"])

    setCrumb(arr)

    init()
 
   }, [refresh, id]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
   )


  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='forum' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-14 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <h3 className="font-bold mb-3 sm:mb-6">Sharing Corner</h3>
              <div className="flex gap-24 w-full">
                <div className="w-full lg:w-3/4">
                  <div className="box-topic">
                    <div className="box-topic-head font-semibold px-6 lg:px-8 py-3.5 lg:py-3">{detail.title}</div>
                    <div className="box-topic-body px-3.5 pb-16 sm:pb-12 sm:pl-8 sm:pr-16">
                      <div className="pt-6 pb-7">
                        <Post needRefresh={needRefresh} id={detail.forum_id} liked={detail.liked} name={detail.name} img={detail.image_url} tlike={detail.total_like} tcomment={detail.total_comment} desc={detail.description} time={detail.dateStr}/>
                      </div>
                      <hr/>
                      <div className="new-topic-user-name font-semibold py-3">All Comments</div>                    
                      <div className="flex gap-3 sm:gap-5 pb-7">
                        <div className="box-topic-body-left hidden sm:block">
                          <UserImg img={myAvatar.get()}/>
                        </div>
                        <div className="box-topic-body-right forum-detail-item-right w-full">
                          <UserImgName name={myName.get()} img={detail.image_url}/>
                          <textarea maxLength="400" value={commentReply} onChange={handleComment} className="w-full" rows="4" placeholder="Tulis komentar di sini …" />
                          <button onClick={()=>replyForum(id)} className="btn-fst w-full font-semibold block mx-auto sm:mr-0 mt-6">Kirim</button>
                        </div>
                      </div>

                      {comments?.map((com) => (
                          <Comment needRefresh={needRefresh} key={'com'+com.comment_id} fid={id} id={com.comment_id} liked={com.liked}  img={com.image_url} name={com.name} time={com.dateStr} comment={com.comment} tlike={com.total_like} tcomment={com.total_comment} child={com.commentsChild}/>
                      ))}
                     
         
                      <div className="mt-14 hidden">
                        <button className="btn-text font-semibold block mx-auto">View more comments</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative hidden lg:block w-1/4">
                  <div className="sticky top-36">
                    <h3 className="font-bold mb-4">Trending Topik :</h3>
                    <div className="trending-topic grid grid-cols-1 gap-5">
                      {trending?.map((trend) => (
                          <ForumItem key={'tr'+trend.forum_id} img={trend.image_url} id={trend.forum_id} title={trend.title} name={trend.name} time={trend.dateStr} comment={trend.description} tlike={trend.total_like} tcomment={trend.total_comment}/>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}