import React, {useState} from 'react'
import {Link} from 'react-router-dom'

const Breadcrumb = (props) => {

  return (
    <div className="breadcrumb hidden sm:flex items-center mb-5">

      {props.list?.map((l, i, row) => (
        <>
        {
          i + 1 === row.length ? 
          <span className="font-semibold"><Link to={l[0]}>{l[1]}</Link></span> : 
          <span><Link to={l[0]}>{l[1]}</Link></span>
        }
        
        {
          i + 1 === row.length ? 
          "" : 
          <span><div className="chev-r"></div></span>
        }
        </>
      ))}
    </div>
  )
}

export default Breadcrumb