import React, { useState, useEffect } from 'react'
import ForumTopicButton from './forum-topic-button'

export default function ListForumTopicButton(props) {
  const [tags, setTags] = useState(props.tags)

  let arrTags = []

  const parentHandleTags = (value) => {

    if(value[1]===false){
      const index = arrTags.indexOf(value[0])

      if(index >-1){
        arrTags.splice(index,1)
        props.parentHandleTopic(arrTags)
      }

    }else{
      
      arrTags.push(value[0])
      props.parentHandleTopic(arrTags)
    }

  }

  return (
    <div className="new-topic-hashtag flex flex-wrap gap-x-2 gap-y-2.5">
      {tags?.map((tg) => {
        return <ForumTopicButton key={tg.tag_id} tag={tg.tag_name} id={tg.tag_id} parentHandleTags={parentHandleTags}/>
      })}
    </div>
  )
}