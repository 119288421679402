import React from 'react'
import {Link} from 'react-router-dom'
import Tags from '../component/tags'
import VideoItemImg from '../component/video-item-img'
import BtnShareInv from '../component/btn-share-inv'

const PopularVideo = (props) => {

  return (
    <div className="video-item collab-hilite block lg:flex shadow-md mb-9">
      <span className="w-full lg:w-3/5">
        <VideoItemImg isfree={props.isfree} key={props} type={props.type} id={props.id} thumb={props.thumb}/>
      </span>
      <span className="w-full lg:w-2/5">
      <div className="video-item-bottom relative px-4 lg:px-6 py-2.5 lg:pt-12 lg:pb-3 h-full">
          <span className="absolute hidden lg:block"></span>
          <Tags cat={props.cat}/>
          <Link to={"/video-play/"+props.id}>
            <div className="video-item-title font-medium leading-tight mb-2">{props.title}</div>
          </Link>
          <div className="video-item-time mb-2.5"><span className="font-semibold">{props.duration}</span></div>
          <Link to={"/video-play/"+props.id}>
            <p className="line-clamp-3 mb-9">{props.content}</p>
          </Link>
          <div className="video-item-like absolute flex items-center"><span className="inline-block mr-1"></span>{props.total_like} Likes</div>
          <BtnShareInv sharelink={"/video-play/"+props.id}/>
        </div>
      </span>
    </div>
  )
}

export default PopularVideo