import React, { useEffect } from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './css/flickity.min.css'
import './css/color.css'
import './css/general.css'
import './css/ckeditor-style.css'
import './css/main.css'
import './css/tab.css'
import ArticleDetail from '../src/page/article-detail'
import Article from '../src/page/article'
import ForumCategory from '../src/page/forum-category'
import ForumDetail from '../src/page/forum-detail'
import ForumTopic from '../src/page/forum-topic'
import Home from '../src/page/home'
import Profile from '../src/page/profile'
import VideoCategory from '../src/page/video-category'
import VideoPlay from '../src/page/video-play'
import VideoProgram from '../src/page/video-program'
import LuckyWheel from '../src/page/lucky-wheel'
import Winners from '../src/page/winners'
import {fetchProfile} from "./api_fetch";
import { hookstate, useHookstate } from '@hookstate/core'
import ReactGA from "react-ga4";

import Terms from '../src/page/terms'
import Privacy from '../src/page/privacy'
import Customer from '../src/page/customer'
import Landing from '../src/page/landing'

export const AVATAR = hookstate()
export const NAME = hookstate()
export const ISLOGIN = hookstate()

export const BREAD = []

function App() {

  const alreadyLogin = useHookstate(ISLOGIN)

  useEffect(()=>{

    ReactGA.initialize("G-YHQK6GG0WG");

    BREAD.push(["/","Home"])
    
    if(localStorage.getItem('sessid')===null){
      AVATAR.set(null);
      NAME.set(null);
      ISLOGIN.set(null);
    }else{
      fetchProfile(data=>{
        AVATAR.set(data.myprofile.image_url)
        NAME.set(data.myprofile.name)
      })
    }

    
  },[])

  const leavePage = () => {
    console.log('LEAVE PAGE')
  }

  return (
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/article-detail/:id" element={<ArticleDetail/>} />
        <Route exact path="/article/:fil" element={<Article/>} />
        <Route exact path="/forum-category/:fil" element={<ForumCategory/>} />
        <Route exact path="/forum-detail/:id" element={<ForumDetail/>} />
        <Route exact path="/forum-detail/:id/:index" element={<ForumDetail/>} />
        <Route exact path="/forum-topic" element={<ForumTopic/>} />
        <Route exact path="/profile" element={<Profile/>} />
        <Route exact path="/profile/:msisdn" element={<Profile/>} />
        <Route exact path="/video-category" element={<VideoCategory/>} />
        <Route exact path="/video-play/:id" element={<VideoPlay/>} onLeave={ leavePage }/>
        <Route exact path="/video-program" element={<VideoProgram/>} />
        <Route exact path="/lucky-wheel" element={<LuckyWheel/>} />
        <Route exact path="/winners" element={<Winners/>} />
        <Route exact path="/terms" element={<Terms/>} />
        <Route exact path="/privacy" element={<Privacy/>} />
        <Route exact path="/customer" element={<Customer/>} />
        <Route exact path="/im3/landing" element={<Landing/>} />
        

      </Routes>  
  );
}

export default App;
