import React, {useState,forwardRef, useEffect} from 'react'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import {Link,useNavigate} from 'react-router-dom'
import {Disclosure,Transition} from '@headlessui/react'
import { fetchWinner } from '../api_fetch'
import Select from 'react-select'

import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

const Winners = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [openTab, setOpenTab] = useState(1);
  // const [selectedPeriode, setSelect] = useState();
  const [spin, setSpin] = useState();
  const [redeem, setRedeem] = useState();
  const [periodeOptions, setPeriode] = useState();

  const [selectedOption, setSelectedOption] = useState(null);

  const CustomIndicator = () => (
    <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/calendar.svg" alt="Calendar Icon" style={{width:'1.25rem',height:'1.25rem',marginRight:'.75rem'}} />
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '2px solid #FFF',
      borderRadius: '10px',
      padding: '0',
      backgroundColor: 'transparent',
      minWidth: '16rem',
      cursor: 'pointer',
      minHeight: 'unset',
      '&:hover': {
        border: '2px solid #FFF'
      }
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: '0'
    }),
    input: (provided) => ({
      ...provided,
      color: '#333',
      padding: '0',
      margin: '0'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#FFF'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px 8px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFF'
    }),
    multiValue: (provided) => ({
      ...provided,
      color: '#333'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#398CFF',
      border: '2px solid #FFF',
      borderRadius: '10px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#398CFF' : '#FFF',
      backgroundColor: state.isFocused ? '#FFF' : '#398CFF',
      '&:hover': {
        backgroundColor: '#FFF',
        color: '#398CFF'
      },
      cursor: 'pointer'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
  };


  const init = () => {
   
    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    const obj = JSON.parse(JSON.stringify(selectedOption));

    let body = {
      periode:null
    }

    if(obj!=null){

      body = {
        periode:obj.value
      }
    }
   

    fetchWinner(body, (data)=>{
      // setPeriode(data.periode)
      setPeriode(data.periode);
      setSpin(data.spin)
      setRedeem(data.redeem)
      setLoading(false);
    });
  }

  useEffect(() => {

   init();

   

  }, [selectedOption]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-14 lg:pt-12">
              <div className="breadcrumb hidden sm:flex items-center mb-5">
                <span><div className="chev-l"></div></span>
                <span><div className="cursor-pointer" onClick={() => navigate(-1)}>Back</div></span>
              </div>
              <div className="lucky-wrapper relative px-6 pt-5 md:pt-8 pb-12 md:pb-24">
                <div className="block lg:hidden">
                  <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/banner.png" alt="Fortune Wheel" />
                </div>
                <div className="flex justify-center mt-7 lg:mt-0 mb-3 md:mb-7">
                  <a className={"lucky-nav-tab " + (openTab === 1 ? "active" : "")} onClick={e => {e.preventDefault();setOpenTab(1)}} data-toggle="tab" role="tablist">Lucky Wheel</a>
                  <a className={"lucky-nav-tab " + (openTab === 2 ? "active" : "")} onClick={e => {e.preventDefault();setOpenTab(2)}} data-toggle="tab" role="tablist">Redeem</a>
                </div>
                <div className="lucky-head relative flex flex-col md:flex-row-reverse items-center justify-between mb-6">
                  <span className="order-2 md:order-1 text-center md:text-left mt-4 md:mt-0 leading-tight">Daftar pemenang Beauty Draw</span>
                  <Select
                    defaultValue={selectedOption} 
                    onChange={setSelectedOption} 
                    options={periodeOptions}
                    placeholder="Pilih Tanggal"
                    styles={customStyles}
                    isSearchable={false}
                    components={{ DropdownIndicator: CustomIndicator }}
                    />
                </div>
                <div className="flex gap-11">
                  <div className={"w-full lg:w-4/6 " + (openTab === 1 ? "block" : "hidden")}>
                    <div className="hidden md:block">
                      <div className="lucky-winner">
                        <div className="flex gap-10">
                          {spin?.map((s) => (
                            <div className="w-1/3">
                              <div className="lucky-winner-head">{s.category}</div>
                                <div className="lucky-winner-body">
                                  <ol>
                                    {s.msisdn?.map((content) => (
                                      <li>{content}</li>
                                    ))}
                                  </ol>
                                </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="block md:hidden">

                      {spin?.map((s) => (
                        <Disclosure as="div" className="accord-winner-item">
                        {({open}) => (
                          <>
                            <Disclosure.Button className="accord-winner-head">{s.category}</Disclosure.Button>
                            <Transition
                              show={open}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel static className="accord-winner-body pb-5">
                                <div className="accord-winner-body-inner flex justify-center">
                                  <ol>
                                    {s.msisdn?.map((content) => (
                                      <li>{content}</li>
                                    ))}
                                  </ol>
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                      ))}
                    </div>
                  </div>
                  <div className={"w-full lg:w-4/6 " + (openTab === 2 ? "block" : "hidden")}>
                    <div className="redeem-winner">
                      <div className="flex flex-col md:flex-row gap-x-11">
                        
                        {redeem.length >2 ?
                          <>
                            <div className="w-full md:w-1/2">
                              {redeem?.slice(0,2).map((r) => (
                                <div className="redeem-winner-item">
                                  <div className="redeem-winner-head flex items-center">
                                    <span className="z-0 hidden md:block"><img src={r.img_url} alt="Makeup" /></span>
                                    {r.category? 
                                    <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" dangerouslySetInnerHTML={{ __html: r.category }}></span>
                                    : 
                                    <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" >{r.category}</span>}
                                    
                                  </div>
                                  <div className="redeem-winner-body flex flex-col md:flex-row justify-center md:justify-end p-3 md:p-0 md:pr-4">
                                    <div className="redeem-winner-body-img">
                                      <img className="block mx-auto md:hidden" src={r.img_url} alt="Makeup" />
                                    </div>
                                    <div className="redeem-winner-body-text flex justify-center pt-3 md:pt-0">
                                      <ol>
                                        {r.msisdn?.map((content) => (
                                          <li>{content}</li>
                                        ))}
                                      </ol>
                                    </div>
                                  </div>
                                </div>
                              ))}                        
                            </div>

                            <div className="w-full md:w-1/2">
                              {redeem?.slice(2).map((r) => (
                                <div className="redeem-winner-item mt-5 md:my-0">
                                  <div className="redeem-winner-head flex items-center">
                                    <span className="z-0 hidden md:block"><img src={r.img_url} alt="Makeup" /></span>
                                    {r.category? 
                                    <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" dangerouslySetInnerHTML={{ __html: r.category }}></span>
                                    : 
                                    <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" >{r.category}</span>}
                                    
                                  </div>
                                  <div className="redeem-winner-body flex flex-col md:flex-row justify-center md:justify-end p-3 md:p-0 md:pr-4">
                                    <div className="redeem-winner-body-img">
                                      <img className="block mx-auto md:hidden" src={r.img_url} alt="Makeup" />
                                    </div>
                                    <div className="redeem-winner-body-text flex justify-center pt-3 md:pt-0">
                                      <ol>
                                        {r.msisdn?.map((content) => (
                                          <li>{content}</li>
                                        ))}
                                      </ol>
                                    </div>
                                  </div>
                                </div>
                              ))}                        
                            </div>
                          </>
                          
                          :
                          <div className="w-full md:w-1/2">
                            {redeem.slice(0,2)?.map((r) => (
                              <div className="redeem-winner-item">
                                <div className="redeem-winner-head flex items-center">
                                  <span className="z-0 hidden md:block"><img src={r.img_url} alt="Makeup" /></span>
                                  {r.category? 
                                  <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" dangerouslySetInnerHTML={{ __html: r.category }}></span>
                                  : 
                                  <span className="flex justify-center md:justify-end text-center py-2 md:py-1 pr-4" >{r.category}</span>}
                                </div>
                                <div className="redeem-winner-body flex flex-col md:flex-row justify-center md:justify-end p-3 md:p-0 md:pr-4">
                                  <div className="redeem-winner-body-img">
                                    <img className="block mx-auto md:hidden" src={r.img_url} alt="Makeup" />
                                  </div>
                                  <div className="redeem-winner-body-text flex justify-center pt-3 md:pt-0">
                                    <ol>
                                      {r.msisdn?.map((content) => (
                                        <li>{content}</li>
                                      ))}
                                    </ol>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                        </div>
                      
                    </div>
                  </div>
                  
                  <div className="hidden lg:block w-2/6">
                    <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/banner.png" alt="Fortune Wheel" />
                  </div>
                  
                </div>
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default Winners