import React, {useState} from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import {Link, useNavigate} from 'react-router-dom'
import WinnerList from '../component/winner-list'
import SpinWheel from '../component/spin-wheel'
import { fetchLuckyWheel, fetchNotif } from '../api_fetch'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

const LuckyWheel = () => {

  
  const navigate = useNavigate();

  const [newNotif, setNewNotif] = useState(false);


  const [isLoading, setLoading] = useState(true);
  const [winners, setWinners] = useState();
  const [prizes, setPrizes] = useState();
  const [idxWin, setIdxWin] = useState(0);

  const init = () => {

    fetchLuckyWheel((data)=>{

      ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
      // commented by request 

      // if(data.hasOwnProperty('rc')){
      //   if(data.rc===99){
      //     navigate('/', {
      //       state: {
      //         needLogin: true
      //       }
      //     });
      //   }else if(data.rc===-2){
      //     navigate('/profile', {
      //       state: {
      //         needSubs: true
      //       }
      //     });
      //   }
      // }else{

      //   let obj = JSON.parse(JSON.stringify(data.winners));
      //   let currentArr = []

      //   for(let i in obj){
      //     currentArr.push(obj[i].name + ' menang ' + obj[i].prize_name)
      //   }

      //   setWinners(currentArr)
      //   setPrizes(data.spin_list);
      //   setIdxWin(data.spin_prize_idx)

      //   setLoading(false)
      // }

      let obj = JSON.parse(JSON.stringify(data.winners));
      let currentArr = []

      for(let i in obj){
        currentArr.push(obj[i].name + ' menang ' + obj[i].prize_name)
      }

      setWinners(currentArr)
      setPrizes(data.spin_list);
      setIdxWin(data.spin_prize_idx)

      setLoading(false)
    })

    checkNotif();
  
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  React.useEffect(() => {
    init()
   }, []);


  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
        </div>
      </div>
   )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header nNotif={newNotif}/>
        <main>
          <div className="content">
            <section className="container-fld">
              <div className="pt-4 pb-14 lg:pt-12">
                <div className="wheel-wrapper pt-3.5 pb-9 md:pt-10 md:pb-28">
                  <img className="block md:hidden" src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/luckywheel_m.png" alt="Lucky Wheel" />
                  <div className="wheel-top-r hidden md:block"><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/top_right.png" alt="Lucky Wheel" /></div>
                  <div className="wheel-bottom-l hidden md:block"><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/bottom_left.png" alt="Lucky Wheel" /></div>
                  <div className="wheel-bottom-r hidden md:block"><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/coin.png" alt="Lucky Wheel" /></div>
                  <div className="wheel-bottom-r-2 hidden md:block"><img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/coin_2.png" alt="Lucky Wheel" /></div>
                  <div className="container flex flex-col md:flex-row items-center gap-10 overflow-hidden md:overflow-visible">
                    <div className="wheel-left flex flex-col items-center w-full md:w-1/2 order-2 md:order-1">
                      <img className="hidden md:block" src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/luckywheel.png" alt="Lucky Wheel" />
                      <WinnerList list={winners}/>
                      {/* <div className="wheel-left-btn px-7 md:px-14 py-2 md:py-3 mt-6">Lihat daftar pemenang <Link to="/winners">disini</Link></div> */}
                    </div>
                    <div className="wheel-right w-full md:w-1/2 order-1 md:order-2">
                      <div className="relative overflow-hidden pt-7 md:pt-10">
                        <SpinWheel list={prizes} idx={idxWin}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default LuckyWheel