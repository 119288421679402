import React from 'react'
import {Link} from 'react-router-dom'
import Flickity from 'react-flickity-component'
import '../css/flickity.min.css'

export default function HomeBanner(props) {
  const optionsHomeBanner = {
    autoPlay: true,
    autoPlay: 3000,
    pageDots: false,
    wrapAround: true
  }
  const optionsHomeTrending = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: false
  }

  return (
    <section className="home-banner-wrapper pb-8 lg:pb-0">
      <div className="home-banner container-cst relative flex items-end">
        <div className="home-banner-bg container-cst absolute bottom-0"></div>
        <span className="home-banner-left hidden md:block sm:w-2/5 z-10">
          <div className="w-full lg:w-5/6 py-7">
            <h2 className="leading-none font-semibold">Welcome to<br/>Pretty Squad!</h2>
            <h6 className="mt-4 mb-3">Special Category :</h6>
            <div className="grid grid-cols-2 gap-x-3 gap-y-2.5 text-center">

            {props.category?.slice(0).reverse().map((cat) => (
                <Link key={'pc'+cat.category_id} to={"video-category?cid=" + cat.category_id}>
                <div className="home-cat-item">
                  <img src={cat.thumb_url} alt="" />
                  <div>{cat.title}</div>
                </div>
              </Link>
            ))}
              
            </div>
          </div>
        </span>
        <span className="home-banner-right sm:w-3/5 w-4/5 mx-auto">
          <Flickity className="carousel carousel-home-banner" elementType="div" options={optionsHomeBanner}>
          {props.top?.map((top) => (
            <div className="carousel-cell">
              <Link to={top.action_url}>
              <div className="home-banner-right-item relative">
                <img src={top.img_web_url} className="absolute h-full object-cover" alt="" loading='lazy'/>
              </div>
              </Link>
            </div>
            
          ))}
          </Flickity>
        </span>
      </div>
      <div className="home-cat-mbl container block md:hidden">
        <p className="mt-4 mb-2">Special Category :</p>
        <Flickity className="carousel text-center" elementType="div" options={optionsHomeTrending}>


          {props.category?.slice(0).reverse().map((cat) => (
            <div className="carousel-cell" key={'mob'+cat.category_id}>
              <Link to={"video-category?cid=" + cat.category_id}>
                <div className="home-cat-item">
                  <img src={cat.thumb_url} alt="" />
                  <div className="mt-1">{cat.title}</div>
                </div>
              </Link>
             </div>
          ))}
          <div className="last-slide"></div>
        </Flickity>
      </div>
    </section>
  )
}