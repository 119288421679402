import React from 'react'
import '../css/landing.css'

export default function Landing() {

  return (
    <section className="landing relative w-full mx-auto">
      <div className="landing-bg absolute top-0 w-full h-full"></div>
      <div className="landing-btm absolute bottom-0 w-full h-full"></div>
      <div className="landing-logo relative mx-auto"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/pretty_squad_logo.svg" alt="" /></div>
      <div className="landing-txt relative text-center italic">Experience your beauty in one place</div>
      <div className="landing-box relative">
        <h6 className="mb-4">Pastikan kamu menggunakan paket data IM3</h6>
        <ol>
          <li>Klik <b>Gabung</b> sekarang juga untuk dapat Kuota Langsung!</li>
          <li>Klik <b>Get OTP</b></li>
          <li>Masukan OTP yang didapat melalui SMS</li>
          <li>Klik <b>Subscribe Now</b></li>
        </ol>
      </div>
      <a href='http://indosat.triyakom.com/?kw=REG%20PRETTY%20VS&sdc=99386&direct=http%3A%2F%2Fklik.123xfun.com%2Fsubs%2Fl%2Ft.aspx'><button className="landing-btn relative mx-auto block">GABUNG</button></a>
      <div className="landing-footer fixed bottom-0 w-full flex items-center justify-center">Copyright &copy; {(new Date().getFullYear())} All rights reserved</div>
    </section>
  )
}