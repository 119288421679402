import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const BtnShareInv = (props) => {
  const [copied, setCopied] = useState(false)
  const url = window.location.origin + props.sharelink

  const onCopyUrl = () => {
    setCopied(true)
  }
  const onBackCopy = () => {
    setCopied(false)
  }

  return (
    // <div className={`inline-block w-full md:w-auto ` + props.className}></div>
    <div className={"btn-share-inv " + props.className}>
      {
        copied ?
        <div className="video-gen-btn video-gen-btn-sec absolute flex items-center justify-center px-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55">
            <defs>
              <clipPath>
                <rect width="25.602" height="25.613" fill="#ae8dff"/>
              </clipPath>
            </defs>
            <circle cx="27.5" cy="27.5" r="27.5" fill="#ae8dff"/>
            <g transform="translate(15.885 14.12)">
              <g clip-path="url(#clip-path)">
                <path d="M6.618,76.07A6.62,6.62,0,0,1,.657,71.936a6.565,6.565,0,0,1,1.144-7.4c1.231-1.4,2.605-2.685,3.943-3.992a1.765,1.765,0,1,1,2.471,2.52c-1.207,1.241-2.468,2.431-3.66,3.686a3.381,3.381,0,0,0,1.572,5.651,3.329,3.329,0,0,0,3.337-.951c1.184-1.181,2.359-2.37,3.55-3.544a1.712,1.712,0,0,1,2.027-.38,1.637,1.637,0,0,1,.993,1.738,2.319,2.319,0,0,1-.547,1.15q-1.831,1.925-3.752,3.762A7.028,7.028,0,0,1,6.618,76.07" transform="translate(0 -50.457)" fill="#fff"/>
                <path d="M76.2,6.927a6.761,6.761,0,0,1-1.966,4.9c-1.179,1.213-2.383,2.4-3.587,3.591a1.779,1.779,0,1,1-2.471-2.536c1.122-1.13,2.257-2.247,3.374-3.382a3.407,3.407,0,1,0-4.837-4.78C65.6,5.832,64.489,6.95,63.373,8.057a1.782,1.782,0,0,1-2.649.164,1.8,1.8,0,0,1,.127-2.652c1.163-1.173,2.313-2.361,3.509-3.5A6.7,6.7,0,0,1,71.517.409,6.683,6.683,0,0,1,76.16,6.035a2.162,2.162,0,0,1,.036.3c.006.2,0,.4,0,.6" transform="translate(-50.598 0.001)" fill="#fff"/>
                <path d="M57.413,48.179a5.246,5.246,0,0,1-.677,1.205q-3.593,3.66-7.245,7.261a1.768,1.768,0,1,1-2.459-2.537q3.594-3.617,7.21-7.212a1.776,1.776,0,0,1,2.029-.4,2.069,2.069,0,0,1,1.143,1.681" transform="translate(-39.045 -38.945)" fill="#fff"/>
              </g>
            </g>
          </svg>
          <span className="ml-2">Copy Link</span>
          <button onClick={onBackCopy}>
            <svg xmlns="http://www.w3.org/2000/svg" width="33.943" height="33.937" viewBox="0 0 33.943 33.937">
              <path d="M-1115.6,2031.026v-.928a2.17,2.17,0,0,0,.064-.217,3.822,3.822,0,0,1,1.785-2.632,3.9,3.9,0,0,1,4.935.651q4.948,4.939,9.887,9.887a2.16,2.16,0,0,1,.227.325h.143a2.188,2.188,0,0,1,.227-.325q4.881-4.889,9.769-9.771c.133-.132.266-.265.406-.389a3.913,3.913,0,0,1,6.121,1.281,3.9,3.9,0,0,1-.874,4.527q-4.941,4.946-9.889,9.885a2.323,2.323,0,0,1-.339.235c.155.163.235.251.319.336l9.794,9.793c.133.132.265.266.389.406a3.921,3.921,0,0,1-1.868,6.344c-.2.056-.4.1-.6.149h-.928a5.385,5.385,0,0,1-3.036-1.861c-3.077-3.109-6.181-6.191-9.271-9.287a1.591,1.591,0,0,1-.2-.344l-.149.059a2.8,2.8,0,0,1-.224.287q-4.931,4.935-9.863,9.867a4.175,4.175,0,0,1-1.776,1.111c-.22.066-.446.114-.669.169h-.928a.943.943,0,0,0-.151-.056,3.839,3.839,0,0,1-3.1-2.66c-.084-.237-.131-.486-.2-.729v-.928a5.46,5.46,0,0,1,1.885-3.061c3.1-3.068,6.177-6.163,9.265-9.244a1.579,1.579,0,0,1,.344-.2l-.059-.147a2.965,2.965,0,0,1-.287-.225q-4.934-4.929-9.867-9.861a4.164,4.164,0,0,1-1.112-1.775C-1115.495,2031.475-1115.543,2031.25-1115.6,2031.026Z" transform="translate(1115.6 -2026.646)" fill="#ae8dff"/>
            </svg>
          </button>
        </div>
        :
        <CopyToClipboard text={url} onCopy={onCopyUrl}>
          <button className="video-item-share absolute" onClick={onCopyUrl}></button>
        </CopyToClipboard>
      }
    </div>
  )
}

export default BtnShareInv