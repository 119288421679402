import React from 'react'

const UserImg = (props) => {

  return (
    <div className={`topic-user-img`}>
      <img src={props.img?props.img:"https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg"} alt="" />
    </div>
  )
}

export default UserImg