import React from 'react'
import {Link} from 'react-router-dom'

export default function Tags(props) {

  return (
    <div className="video-item-label flex flex-wrap gap-x-2 gap-y-1 mb-2">
      {props.cat?.map((c) => (

        <Link to={"/video-category?cid="+c.category_id} key={"tag"+c.category_id}><label className={c.category_id===1?"purple" :
                          c.category_id===2?"blue" :
                          c.category_id===3?"orange" :
                          c.category_id===4?"green" :
                          c.category_id===5?"pink" : ""
        + " cursor-pointer"}>{c.title}</label>
        </Link>
      ))}
    </div>
  )
}