import React from 'react'
import UserImg from '../component/user-img'

export default function CommentChild(props) {

  return (
    <div className="comment-reply-item">
      <div className="comment-name flex items-center gap-1.5 font-semibold"><span className="xs:hidden"><UserImg img={props.img}/></span><span>{props.name}</span></div>
      <div className="comment-p mt-2 sm:mt-1.5">{props.comment}</div>
      <div className="comment-time mt-3.5 sm:mt-2">{props.time}</div>
    </div>
  )
}