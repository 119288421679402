import React, {useEffect,useState} from 'react'
import {motion} from 'framer-motion'
import { useStateWithCallback } from '../useStateWithCallback'

const WinnerList = (props) => {

  const [winners, setwinners] = useState(props.list);

  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(()=>{
      addWinner()
  },[])

  function addWinner(){

     const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % winners.length);
    }, 2000);

    return () => clearInterval(intervalId);
    
  }

  return (
    <div className="wheel-box-outer">
      <div className="wheel-box">
        <ul>
          {winners.map((winner,index) => (
            <motion.li key={index} dangerouslySetInnerHTML={{__html: winner}}
              animate={{
                x: ["-50%","-50%"],
                y: (index - activeIndex + winners.length) % winners.length * 45,
                opacity: 1,
                scale: index === (activeIndex + 3) % winners.length ? 1.2 : 1,
                zIndex: index === (activeIndex + 3) % winners.length ? 2 : 1
              }}
            ></motion.li>
          ))}
        </ul>
      </div>
    </div>
  )
  
  
}

export default WinnerList