import React from 'react'
import UserImg from '../component/user-img'
import UserImgNameTime from '../component/user-img-name-time'
import { fetchLike } from '../api_fetch'

const Post = (props) => {

  const likey = (type, flagLike) => {

    let param;

    if(flagLike === 1)
      flagLike=0;
    else
      flagLike=1
    
    if(type ==='forum')
      param = {
        forum_id : props.id,
        liked : flagLike
      }
    else
      param = {
        comment_id : props.id,
        liked : flagLike
      }  

    fetchLike(param, (data)=>{
      
      props.needRefresh()
    });

    
  }

  

  return (
    <div className="flex gap-3 sm:gap-5">
      <div className="box-topic-body-left hidden sm:block">
        <UserImg img={props.img}/>
      </div>
      <div className="box-topic-body-right forum-detail-item-right">
        <UserImgNameTime time={props.time} name={props.name}/>
        <div className="forum-detail-item-cmt xs:ml-auto">
          <p>{props.desc}</p>
          <div className="mt-3.5">
            <span className="forum-detail-like forum-item-body-like-right flex items-center">
              <span onClick={()=>likey('forum',props.liked)}><div className={props.liked?"flex items-center liked":"flex items-center"}><span></span><span>{props.tlike}</span></div></span>
              <div className="flex items-center ml-3 lg:ml-5"><span></span><span>{props.tcomment}</span></div>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post