import React from 'react'
import {Link} from 'react-router-dom'
import Tags from '../component/tags'
import VideoItemImg from '../component/video-item-img'
import BtnShareInv from '../component/btn-share-inv'

const TrendingHilite = (props) => {

  return (
    <div className="video-item shadow-md trending-hilite mb-8 sm:mb-0">
      <VideoItemImg type={props.type} id={props.id} thumb={props.thumb} isfree={props.isfree}/>
      <div className="video-item-bottom relative px-4 sm:px-6 py-2.5 sm:pt-6 sm:pb-3">
        <Tags cat={props.cat}/>
        <Link to={"/video-play/"+props.id}>
          <div className="video-item-title font-medium leading-tight mb-2">{props.title}</div>
        </Link>
        <div className="video-item-time mb-2.5">
          <span className="font-semibold">{props.duration}</span>
          </div>
        <Link to={"/video-play/"+props.id}>
          <p className="hidden sm:block sm:line-clamp-8 mb-9">{props.desc}</p>
        </Link>
        <div className="video-item-like hidden sm:flex items-center"><span className="inline-block mr-1"></span>{props.total_like} Likes</div>
        <BtnShareInv sharelink={"/video-play/"+props.id}/>
      </div>
    </div>
  )
}

export default TrendingHilite