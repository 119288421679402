import React , {useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import Filter from '../component/filter'
import ForumItem from '../component/forum-item'
import { useParams } from "react-router-dom";
import { fetchForum, fetchNotif } from '../api_fetch'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'


export default function ForumCategory() {

  const { fil } = useParams();

  const [result,setResult] = useState();
  const [isLoading, setLoading] = useState(true);

  const [sort, setSort] = useState(fil);

  const [message, setMessage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalpage, setTotalPage] = useState(0)

  const [newNotif, setNewNotif] = useState(false);

  let arr = [];
  const [crumb, setCrumb] = useState([])

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    let body = {
      filterType : sort,
      page: 1
    }

    fetchForum(body, (data)=>{
      setCurrentPage(1)
      setResult(data.topics)
      setTotalPage(data.totalPage)
      setLoading(false);
    });

    checkNotif()
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }


  const parentHandleChange = (childValue) => {
    setSort(childValue)
  }

  const morePage = () => {

    let body = {
      filterType: sort,
      page: currentPage + 1
    }

    fetchForum(body, (data)=>{

      let currentArr = [];  
      currentArr = result;

      let obj = JSON.parse(JSON.stringify(data.topics));

      for(let i in obj){
        currentArr.push(obj[i])

      }

      setResult(currentArr)
      setCurrentPage(currentPage+1);
    });
  }
  
  React.useEffect(() => {

    arr=[]
    arr.push(["/","Home"])
    arr.push(["/forum-category/1","Forums"])

    setCrumb(arr)

    init()
 
   }, [sort]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
   )
  

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='forum' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-14 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <div className="flex items-center justify-between mb-6">
                <span><h3 className="font-bold">Sharing Corner</h3></span>
                <span className="sm:hidden flex items-center">
                  <Filter parentHandleChange={parentHandleChange}/>
                </span>
              </div>
              <div className="flex items-center justify-between xs:flex-col mb-8">
                <span className="filter-topic hidden sm:block">
                  <Filter parentHandleChange={parentHandleChange} mode="forum"/>
                </span>
                <span className="flex justify-center sm:justify-end">
                  <Link to="/forum-topic">
                    <button className="btn-new-topic flex items-center px-10 py-1.5 sm:pl-3 sm:py-1 sm:pr-1">
                      <span className="font-semibold">Create New Topic</span>
                      <span className="ml-2"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/new_icon.svg" alt="" /></span>
                    </button>
                  </Link>
                </span>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-7 gap-y-11">

                {result?.map((data) => (
                     <ForumItem key={data.forum_id} img={data.image_url} id={data.forum_id} title={data.title} name={data.name} time={data.dateStr} comment={data.description} tlike={data.total_like} tcomment={data.total_comment}/>
                ))}
          
              </div>
              {
                currentPage < totalpage &&
                <button onClick={morePage} className="btn-more mx-auto mt-16 mb-12 flex items-center justify-center px-1 py-2 w-full">Load More</button>
              }
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}