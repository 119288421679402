import React, {useRef,useEffect, useState} from 'react'
import Breadcrumb from '../component/breadcrumb'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import { FocusTrap } from '@headlessui/react'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'
import { fetchNotif } from '../api_fetch'

const Privacy = () => {
  const buttonRef = useRef();

  const [newNotif, setNewNotif] = useState(false);


  let arr = [];
  const [crumb, setCrumb] = useState([])

  const handleScrollToTop = () => {
    const scrollToTop = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > 0) {
        window.scrollTo(0, scrollTop - scrollTop / 8);
        requestAnimationFrame(scrollToTop);
      }
    };

    scrollToTop();
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 100) {
      buttonRef.current.classList.add('show');
    } else {
      buttonRef.current.classList.remove('show');
    }
  };


  useEffect(() => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    arr=[]
    arr.push(["/","Home"])
    arr.push(["/privacy","Kebijakan Privasi"])

    setCrumb(arr)

    checkNotif();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content"></div>
          <section className="container pt-4 pb-14 lg:pt-12">
            <Breadcrumb list={crumb}/>
            <div className="wrapper-text relative px-5 sm:px-16 pt-8 pb-10 sm:pb-20">
              <div className="wrapper-text-header sticky sm:relative sm:w-auto -left-5 sm:left-auto -top-8 sm:top-auto mb-3 px-5 sm:px-0 py-2 sm:py-0">
                <h6>Kebijakan Privasi</h6>
              </div>
              <p>Pretty Squad adalah situs layanan berlangganan untuk konsumen yang terdiri dari program Membership, Points dan Rewards yang terintegrasi di PT TRIYAKOM. Kebijakan privasi ini merupakan bentuk komitmen dari PT TRIYAKOM untuk selalu melindungi dan menghargai setiap informasi pribadi pengguna situs www.prettysquad.co.id (“PRETTY SQUAD”).
                <br/><br/>
                Kebijakan privasi menjelaskan bagaimana kami mengelola, menggunakan, melindungi dan dalam beberapa hal, mengungkapkan segala informasi dan juga data pribadi (sebagaimana didefinisikan dibawah ini) yang Anda berikan kepada kami sehubungan dengan penggunaan terhadap situs, yang mana merupakan suatu kepatuhan kami terhadap peraturan perundang-undangan Republik Indonesia.
                <br/><br/>
                Dengan mengakses dan menggunakan situs, user dianggap telah membaca, memahami, dan memberikan persetujuan kepada kami atas pengumpulan, pemrosesan, pengungkapan, dan penggunaan informasi dan juga data pribadi Anda sebagaimana diuraikan di bawah ini.
                <br/><br/>
                Kebijakan Privasi dapat berubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan dan/atau pengumuman terlebih dahulu.
              </p>
              <ol className="list-decimal mt-6 pl-7">
                <li>
                  <b>Informasi Apa Yang Kami Kumpulkan Mengenai Anda</b>
                  <ul className="no-marker">
                    <li>
                      <b>Pendaftaran akun Pretty Squad</b>
                      Untuk mendaftar akun di Pretty Squad, Anda harus memberikan alamat email, nomor ponsel, serta menyetujui ketentuan layanan dan kebijakan privasi Pretty Squad.
                      <br/><br/>
                      User Generated Content/UGC (konten buatan pembaca/pengguna) termasuk likes, komentar, tulisan dan ulasan. Pretty Squad menawarkan kepada Anda untuk terlibat dalam aktivitas publik seperti mengirimkan tulisan/ulasan, memberikan likes, dan berkomentar pada tulisan/ulasan pengguna lain. Informasi yang Anda kirimkan dapat dilihat secara publik, dikumpulkan dan digunakan oleh pengguna lain dari layanan tersebut.
                    </li>
                    <li>
                      <b>Kuis dan Lucky Wheel</b>
                      Anda dapat mengikuti kuis dan lucky wheel berhadiah di halaman situs Pretty Squad. Kegiatan ini diselenggarakan sendiri oleh Pretty Squad. Saat mengikuti kegiatan tersebut Anda diminta untuk login dan juga berlangganan. Kemudian, Anda diminta untuk mengisi data pribadi guna memverifikasi kebenaran penerima hadiah.
                    </li>
                    <li>
                      <b>Point</b>
                      Anda dapat mengikuti program redeem point untuk mendapatkan hadiah. Untuk dapat mereedem point, Anda diharuskan untuk berlangganan Pretty Squad dan mengirimkan data diri Anda berupa nomor ponsel. Mohon untuk melihat syarat dan ketentuan mengenai program reedem point di halaman berikut.
                    </li>
                    <li>
                      <b>Program Berhadiah</b>
                      Anda dapat mengikuti program berhadiah yang diadakan oleh Pretty Squad. Untuk dapat mengikuti program berhadiah, maka Anda diharuskan untuk berlangganan Pretty Squad. Mekanisme program berhadiah akan dijabarkan pada halaman khusus program berhadiah. User wajib mengirimkan data diri berupa nomor ponsel, alamat lengkap dan data pribadi lainnya yang akan di teruskan oleh Customer Service Pretty Squad. Data diri Anda berguna sebagai pengiriman hadiah dan memverifikasi kebenaran penerima hadiah.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Data Pribadi</b>
                  <p>Data pribadi merupakan data dan informasi dalam bentuk apapun yang dapat mengidentifikasi anggota dari waktu ke waktu anggota sampaikan dan sertakan di situs Pretty Squad. Informasi yang dikumpulkan dan disimpan oleh situs kami dilakukan secara otomatis dan menyangkut pribadi anggota yang meliputi nama lengkap, alamat email, nomor ponsel (termasuk tidak terbatas pada: alamat IP, informasi lokasi, data perangkat, nomor IMEI, data mengenai informasi tentang aktivitas antar anggota menggunakan situs Pretty Squad dan/atau strategic partner, data dari sumber lain seperti informasi pengiriman dan alamat yang akan ditanyakan secara manual oleh costumer service kami, yang juga kami gunakan untuk memperbaiki catatan kami dan mengirimkan hadiah untuk user yang berhak mendapatkan hadiah dan/atau rewards dari Pretty Squad sesuai dengan syarat dan ketentuan yang berlaku.</p>
                </li>
                <li>
                  <b>Pengumpulan Data Pribadi</b>
                  <p>Situs kami menggunakan “cookies”, piksel dan pengidentifikasian unik lainnya. Hal ini dilakukan agar kami dapat memberikan rekomendasi konten, artikel dan mempelajari lebih lanjut mengenai minat user terhadap situs Pretty Squad serta membantu kami dalam meningkatkan layanan kami terhadap user.</p>
                </li>
                <li>
                  <b>Penggunaan Data Pribadi</b>
                  <p>Pretty Squad akan memproses, menganalisa dan/atau menggunakan data pribadi user untuk tujuan yang diizinkan oleh hukum dan peraturan yang berlaku untuk :</p>
                  <ul>
                    <li>Komunikasi dengan user lainnya di Pretty Squad</li>
                    <li>Menyediakan akses layanan pada situs</li>
                    <li>Rekomendasi dan personalisasi</li>
                    <li>Menyediakan, memecahkan dan meningkatkan layanan Pretty Squad</li>
                    <li>Pengiriman hadiah dan/atau rewards kepada pemenang yang berhak sesuai dengan syarat dan ketentuan yang berlaku.</li>
                  </ul>
                  <p>Mematuhi kewajiban hukum (kami mengumpulkan dan menggunakan data pribadi anggota untuk mematuhi hukum). Misalnya, kami mengumpulkan informasi untuk verifikasi identitas dari pemenang hadiah dan/atau rewards dari layanan Pretty Squad.</p>
                </li>
                <li>
                  <b>Pengungkapan Terhadap Pihak Ketiga</b>
                  <p>Kami tidak akan mengalihkan, menjual, mendistribusikan, mengungkapkan, atau meminjamkan informasi dan juga data pribadi user kepada pihak ketiga lain, kecuali untuk ketentuan sebagai berikut :</p>
                  <ul>
                    <li>Kami dapat mengungkapkan kepada pihak ketiga lainnya yang bertindak sebagai penyedia jasa kami untuk membantu kami menyediakan layanan kepada pengguna. Penyedia jasa tersebut termasuk kepada penyedia layanan konsumen (customer service), dan penyedia layanan transaksi pembayaran.</li>
                    <li>Kami dapat mengalihkan data pribadi secara keseluruhan atau sebagian besar kepada pihak ketiga dalam hal dilakukanya aksi korporasi tidak terbatas pada penggabungan, peleburan, pengambilalihan atau reorganisasi yang menyebabkan beralihnya penguasaan atas kepemilikan perseroan.</li>
                    <li>Kami dapat membagikan informasi data pribadi kepada pihak ketiga lainnya untuk menunjang kebutuhan kami dalam rangka meningkatkan dan mengembangkan kepentingan pemasaran dan pengembangan bisnis Perseroan.</li>
                  </ul>
                </li>
                <li>
                  <b>Hak Kekayaan Intelektual</b>
                  <p>Seluruh produk Pretty Squad termasuk desain, tampilan, logo, foto, gambar, nama, kata, huruf, angka, tulisan, susunan warna dan seluruh unsur sepenuhnya merupakan hak milik Pretty Squad dan tidak ada pihak lain yang juga memiliki hak yang sama. User tidak diperbolehkan untuk :</p>
                  <ul>
                    <li>Menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari, mendistribusikan, menjual, mentransfer, menampilkan kepada publik memperbanyak mentransmisikan, mentransfer, menyiarkan, mendeskripsikan, atau membongkar bagian manapun dari atau dengan cara lain mengeksploitasi layanan Pretty Squad yang dilisensikan kepada user.</li>
                    <li>Melisensikan, mensublisensikan, menjual, menjual kembali, mentransfer, mendistribusikan atau mengeksploitasi secara komersial atau dengan cara lain menyediakan perangkat lunak dan/atau perangkat lunak kepada pihak ketiga dengan cara apapun.</li>
                    <li>Mempublikasikan, mendistribusikan atau memperbanyak dengan cara apapun materi berhak cipta, merek dagang, atau informasi lain dari Pretty Squad tanpa memperoleh persetujuan sebelumnya dari hak milik Pretty Squad atau pemilik hak yang melisensikan haknya kepada Pretty Squad.</li>
                    <li>Menghapus hak cipta, merek dagang, atau informasi kepemilikan lainnya yang terkandung dalam produk/layanan Pretty Squad.</li>
                  </ul>
                </li>
                <li>
                  <b>Permintaan Akses Perubahan Data Pribadi</b>
                  <p>User dapat meminta akses atau meminta perubahaan data pribadi user dengan menghubungi Layanan Pelanggan Pretty Squad dengan menyertakan bukti/dokumen pendukung melalui :</p>
                  <ul>
                    <li>Customer Service (021) 52971540</li>
                    <li>Instagram : @prettysquad_id</li>
                    <li>Email : iamprettysquad@gmail.com</li>
                  </ul>
                </li>
                <li>
                  <b>Permintaan Penghapusan Data Pribadi</b>
                  <ul>
                    <li>User berhak meminta penghapusan akun Pretty Squad dengan menghubungi customer service (021-52971540) disertai bukti dan dokumen pendukung.</li>
                    <li>Seluruh penghapusan akun dapat berakibat terhapusnya seluruh akses ke situs dan layanan Pretty Squad.</li>
                    <li>Pretty Squad tidak bertanggung jawab terhadap kerugian dalam penghapusan akun dan terhapusnya seluruh akses ke situs kami.</li>
                    <li>Pretty Squad berhak menolak permintaan penghapusan akun dan sewaktu-waktu melakukan perubahan terhadap kebijakan privasi tanpa pemberitahuan dan alasan sebelumnya.</li>
                  </ul>
                </li>
                <li>
                  <b>Pernyataan dan Persetujuan Anggota</b>
                  <p>Berdasarkan kebijakan privasi Pretty Squad di atas dengan ini user menyatakan :</p>
                  <ul>
                    <li>Bahwa data pribadi yang diberikan user merupakan data yang benar dan sah, oleh karenanya user memberikan persetujuan kepada Pretty Squad untuk memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan, menampilkan, mengumumkan, mengirimkan, menyebarluaskan, menghapus dan memusnahkan sesuai dengan kebijakan privasi dan perundang-undangan yang berlaku.</li>
                    <li>Bahwa user telah membaca, memahami dan menyetujui seluruh ketentuan yang terdapat dalam kebijakan privasi ini dan menjadi satu kesatuan yang tak terpisahkan dengan syarat dan ketentuan Pretty Squad.</li>
                    <li>Bahwa user membebaskan Pretty Squad dari setiap tuntutan, gugatan, ganti rugi, dan/atau klaim sehubungan dengan kegagalan pemrosesan transaksi yang disebabkan oleh ketidakakuratan/ketidakbenaran data pribadi yang user berikan kepada Pretty Squad.</li>
                    <li>Bahwa user telah memberikan persetujuan, izin, dan wewenang kepada Pretty Squad untuk mengumumkan, mengirimkan, dan/atau menyebarluaskan serta memberikan akses atas data pribadi kepada pihak ketiga untuk tujuan-tujuan sebagaimana dimaksud dalam poin 4 di atas.</li>
                  </ul>
                </li>
                <li>
                  <b>Masa Berlaku</b>
                  <p>Kebijakan Privasi ini berlaku dan mengikat secara hukum sejak user berhasil registrasi.</p>
                </li>
              </ol>
              <div className="last-updt">Last Update 29 Maret 2023</div>
              <button ref={buttonRef} className="btn-up hidden sm:block absolute bottom-8 right-6" onClick={handleScrollToTop}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/up.svg" alt="Up" /></button>
            </div>
          </section>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default Privacy