import React from 'react'
import {Link} from 'react-router-dom'

export default function VideoImgTags(props) {

  let custom;
  let id= props.id;

  if(props.type === 'premium'){
    custom = "/video-play/" + id;
  }else{
    custom = "/article-detail/" + id;
  }

  return (
    
    <Link to={custom}>
      <div className="video-item-img relative">
        <img src={props.thumb} className="absolute h-full" alt="" />
        <span className={props.isfree===0?"absolute":""}></span>
        <div className="absolute abs-center"></div>
      </div>
    </Link>
  )
}