import React, {useState} from 'react'
import Flickity from 'react-flickity-component'
import VideoItem from '../component/video-item'

export default function SliderContent(props) {

  const [isLoading, setLoading] = useState(true);


  const optionsContWatch = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: false
  }

  
  React.useEffect(() => {

    if(props!='undefined'){
      setLoading(false)
    }

  }, []);

  if(isLoading)
    return (
      <></>
    )
  
  return (
    <div className="px-5 md:px-0">
      <div className="slider-cont mt-7 sm:mt-16">
        <div className="slider-cont-sub font-semibold -mx-5 mb-5">{props.category}</div>
        <Flickity className="carousel deep-carousel carousel-collab block lg:hidden" elementType="div" options={optionsContWatch}>

          {props.data?.map((data) => (
            <div key={"pc"+data.video_id} className="carousel-cell">
              <VideoItem type="premium" cat={data.categories} isfree={data.flag_free} key={"pc"+props.category+data.video_id} thumb={data.thumb_url}  id={data.video_id} title={data.title} time={data.dateStr} duration={data.durationStr}/>
            </div>
          ))}

          <div className="last-slide"></div>
        </Flickity>
        <div className="hidden lg:grid grid-cols-4 gap-8 px-6">                    
       
        {props.data?.map((data) => (
            <VideoItem type="premium" cat={data.categories} isfree={data.flag_free} key={"mobile"+props.category+data.video_id} id={data.video_id} thumb={data.thumb_url} title={data.title} time={data.dateStr} duration={data.durationStr} category={data.category}/>
        ))}
        </div>
      </div>
    </div>
  )
}