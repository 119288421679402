import React, {useState} from 'react'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import VideoPlayItem from '../component/video-play-item'
import SliderContent from '../component/slider-content'
import ReactPlayer from 'react-player'
import BtnShare from '../component/btn-share'
import Flickity from 'react-flickity-component'

import { useNavigate, useParams } from "react-router-dom";
import { fetchVideoDetail, fetchVideoTime, fetchVideoLike, fetchNotif } from '../api_fetch'

import { ISLOGIN } from '../App'
import ReactGA from "react-ga4";
import { useHookstate } from '@hookstate/core'
import { GlobalData } from '../component/global'

const VideoPlay = () => {

  const { id } = useParams();  

  const navigate = useNavigate();

  const alreadyLogin = useHookstate(ISLOGIN);

  const [detailVideo, setDetail] = useState()
  const [newVideo, setNewVideo] = useState()
  const [otherVideo, setOtherVideo] = useState()
  const [otherVideo2, setOtherVideo2] = useState()
  const [popularVideo, setPopularVideo] = useState()
  const [isLoading, setLoading] = useState(true);

  const [isReady, setIsReady] = React.useState(false);
  const [refresh, setRefresh] = useState(0);

  const [newNotif, setNewNotif] = useState(false);

  const playerRef = React.useRef();

  let arr = [];
  const [crumb, setCrumb] = useState([])

  let cont = 0;
  let counter = 0;

  const onReady = React.useCallback(() => {
    if (!isReady) {
      const timeToStart = cont;
      playerRef.current.seekTo(timeToStart, "seconds");
      setIsReady(true);
    }
  }, [isReady]);

  const optionsVideoPlay = {
    freeScroll: false,
    contain: true,
    prevNextButtons: false,
    pageDots: true,
    wrapAround: false
  }

  const checkPoint = (s) => {

    counter = Math.floor(s) % 5
   
    if(counter===4 && isReady===true){
      vtime(Math.floor(s))
    }
  }

  const vtime = (sec) => {

    const param = {
      video_id : id,
      time : sec
    }

    fetchVideoTime (param, (data) =>{
    
    })
  }

  const likey = (flagLike) => {

    if(flagLike === 1)
      flagLike=0;
    else
      flagLike=1
    
  
    let param = {
      video_id : id,
      liked : flagLike
    }  

    fetchVideoLike(param, (data)=>{
      setRefresh(refresh+1)
    });

    
  }

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    if(localStorage.getItem('sessid')===null){
      navigate('/', {
        state: {
          needLogin: true
        }
      });
    }

    arr.push(["/","Home"])
    arr.push(["/video-category?cid=5","Video"])
    arr.push(["/video-play/"+id,"Play Video"])

    setCrumb(arr)

    const param = {
      video_id : id,
      filter : 1,
    }

    fetchVideoDetail(param, (data)=>{

      if(data.detail){

        cont = data.detail.at_time
        setDetail(data.detail)
        setNewVideo(data.newest)
        setPopularVideo(data.popular)
        setOtherVideo(data.other.slice(0,5))
        setOtherVideo2(data.other.slice(5,10))
        setLoading(false);
      }else{
        navigate('/profile', {
          state: {
            needSubs: true
          }
        });
      }     
      
    });

    checkNotif()
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  const process = () => {
    
  }

  React.useEffect(() => {
    init()
    
   }, [id, refresh]);

  if(isLoading)
  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header/>
        <FloatingBtn/>
      </div>
    </div>
  )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='video' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="pt-4 pb-16 lg:pt-12">
              <div className="container" onClick={process}><Breadcrumb list={crumb}/></div>
              <div className="video-wrapper container">
                <div className="flex gap-12 flex-col lg:flex-row">
                  <div className="video-play-wrapper">
                    <div className="react-player-wrapper">
                      <ReactPlayer
                        ref={playerRef}
                        className="react-player"
                        url={detailVideo.video_url}
                        controls={true}
                        playing={true}
                        width='100%'
                        height='100%'
                        onProgress={(progress) => {
                          
                          checkPoint(progress.playedSeconds);
                        }}
                        onReady={onReady}
                      />
                    </div>
                    <div className="video-play px-2 pt-3">
                      <div className="video-play-detail flex items-center justify-between">
                        <span className="video-play-time"><b>{detailVideo.durationStr}</b> .</span>
                        <span className="video-play-like" onClick={()=>likey(detailVideo.liked)}><div className={detailVideo.liked?"video-item-like flex items-center liked":"video-item-like flex items-center"}><span className="inline-block mr-1"></span>{detailVideo.total_like} Likes</div></span>
                      </div>
                      <h5 className="font-semibold mt-4">{detailVideo.title}</h5>
                      <div className="video-play-p mt-3">
                        <p>{detailVideo.description}</p>
                      </div>
                      <BtnShare className="mt-7" sharelink={"/video-play/"+id}/>
                      {/* <WhatsappShareButton className="video-gen-btn flex items-center justify-center xs:w-full px-4 sm:px-6" url="https://prettysquad.co.id">
                        <WhatsappIcon round={true} size={20}/>
                      </WhatsappShareButton> */}
                    </div>
                  </div>
                  <div className="video-play-item-wrapper pl-8 hidden lg:block">
                    <h6 className="font-semibold">Video Lainnya :</h6>
                    <Flickity className="carousel carousel-video-play" elementType="div" options={optionsVideoPlay}>
                      <div className="carousel-cell">
                        <div className="grid grid-cols-1 gap-4 mt-3">

                        {otherVideo?.map((ot) => (
                          <VideoPlayItem key={"ot"+ot.video_id} id={ot.video_id} title={ot.title} time={ot.dateStr} duration={ot.durationStr} thumb={ot.thumb_url} desc={ot.description} cat={ot.categories}/>
                        ))}
                          
                        </div>
                      </div>
                      {otherVideo2.length > 0 &&
                        <div className="carousel-cell">
                          <div className="grid grid-cols-1 gap-4 mt-3">

                          {otherVideo2?.map((ot2) => (
                            <VideoPlayItem key={"ot2"+ot2.video_id} id={ot2.video_id} title={ot2.title} time={ot2.dateStr} duration={ot2.durationStr} thumb={ot2.thumb_url} desc={ot2.description} cat={ot2.categories}/>
                          ))}
                            
                          </div>
                        </div>
                      }
                    </Flickity>
                  </div>
                </div>
                <hr className="mt-16"/>
                <SliderContent category="New Video" data={newVideo}/>
                <hr className="mt-16"/>
                <SliderContent category="Populer Video" data={popularVideo}/>
                {/* <hr className="mt-16"/>
                <SliderContent/> */}
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default VideoPlay