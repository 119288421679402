import React from 'react'
import {Link} from 'react-router-dom'

export default function ArticleList(props) {

  return (
    <li className="article-list">
      <Link to={"/article-detail/"+props.id}>
        <div className="flex gap-6">
          <span className="w-4/12">
            <div className="article-list-img relative">
              <img loading="lazy" src={props.image} className="absolute h-full object-cover" alt="" />
            </div>
          </span>
          <span className="article-list-detail w-8/12">
            <div>
              <h6 className="font-semibold leading-none line-clamp-2">{props.title}</h6>
              <p className="mt-1.5 line-clamp-3" dangerouslySetInnerHTML={{ __html: props.content }}/>
            </div>
            <button className="font-semibold">LEARN MORE</button>
          </span>
        </div>
      </Link>
    </li>
  )
}