import React from 'react'
import {Link} from 'react-router-dom'

export default function ForumItem(props) {

  return (
    <Link to={"/forum-detail/"+props.id}>
      <div className="forum-item">
        <div className="forum-item-head font-medium px-4 lg:px-8 py-1 lg:py-4 line-clamp-1">{props.title}</div>
        <div className="forum-item-body pl-3.5 lg:pl-10 pr-3.5 lg:pr-5 pt-2 lg:pt-2.5 pb-3 lg:pb-7">
          <div className="forum-item-body-inner forum-item-body-user items-center gap-2">
            <span><div><img src={props.img?props.img:"https://s.tykcdn.com/assets/pretty_squad/icon/user_def.svg"} alt="" /></div></span>
            <span className="font-semibold">{props.name}</span>
          </div>
          <div className="forum-item-body-inner forum-item-body-detail gap-2 mt-1.5">
            <span><div className="connect-dot"></div></span>
            <span>
              <div className="forum-item-body-detail-time">{props.time}</div>
              <div className="line-clamp-4 lg:line-clamp-3 forum-item-body-detail-p mt-1.5">{props.comment}</div>
            </span>
          </div>
          <div className="forum-item-body-inner forum-item-body-like gap-2 mt-2">
            <span></span>
            <span className="forum-item-body-like-right flex items-center">
              <div className="flex items-center"><span></span><span>{props.tlike}</span></div>
              <div className="flex items-center ml-3 lg:ml-5"><span></span><span>{props.tcomment}</span></div>
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}