import React from 'react'
import {Link} from 'react-router-dom'

const ArticleItem = (props) => {

  return (
    <div className="article-item">
      <Link to={"/article-detail/" + props.id}>
        <div className="flex gap-7">
          <span className="article-item-cover">
            <div className="article-item-img relative">
              <img loading="lazy" src={props.img?props.img:"https://s.tykcdn.com/assets/pretty_squad/img/img_banner_program.webp"} className="absolute h-full object-cover" alt="" />
            </div>
          </span>
          <span className="article-item-detail">
            <div>
              <h5 className="font-semibold leading-none line-clamp-2 lg:line-clamp-3">{props.title}</h5>
              <p className="mt-3 line-clamp-3 lg:line-clamp-5" dangerouslySetInnerHTML={{ __html: props.content }}/>
            </div>
            <div className="article-item-time mt-1.5">{props.time}</div>
          </span>
        </div>
      </Link>
    </div>
  )
}

export default ArticleItem