import React from 'react'
import UserImg from '../component/user-img'

const UserImgName = (props) => {

  return (
    <div className="new-topic-user flex items-center gap-3 py-2">
      <span className="new-topic-user-img block sm:hidden"><UserImg img={props.img}/></span>
      <span className="new-topic-user-name font-semibold">{props.name}</span>
    </div>
  )
}

export default UserImgName