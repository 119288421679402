import React, {useState} from 'react'
import {Listbox} from '@headlessui/react'

export default function Filter(props) {

  let filteritem = [
    { id: 0, name: 'Filter', unavailable: false },
    { id: 1, name: 'Terbaru', unavailable: false },
    { id: 2, name: 'Terpopuler', unavailable: false }, 
    { id: 3, name: 'Paling banyak dilihat', unavailable: false }
    ]

  if(props.mode==='article'){
    filteritem = filteritem.slice(0,3)
  }  

  const [selectedFilter, setSelectedFilter] = useState(filteritem[0])

  const handleChange = (e) =>{
    setSelectedFilter(filteritem[e.id])
    props.parentHandleChange(e.id)
  }

  return (
    <Listbox value={selectedFilter} onChange={handleChange}>
      <div className="relative">
        <Listbox.Button className="filter-body-wrapper w-full"><div className="filter-head hidden sm:flex items-center justify-between pl-6 pr-3"><span>{selectedFilter.name}</span><span></span></div><div className="block sm:hidden"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/filter.svg" alt="" /></div></Listbox.Button>
        <Listbox.Options className="filter-body absolute w-full z-10">
          {filteritem.map((filt) => (
            <Listbox.Option className="filter-item flex items-center px-6 xs:py-3 overflow-hidden"
              key={filt.id}
              value={filt}
            >
              {filt.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}