import React, {useState} from 'react'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import VideoItem from '../component/video-item'
import SvgArrow from '../component/svg-arrow'
import SliderContent from '../component/slider-content'
import BtnShare from '../component/btn-share'

import { fetchVideoSpesial, fetchMoreVideo } from '../api_fetch'

import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

const VideoProgram = () => {


  let arr = [];
  const [crumb, setCrumb] = useState([])
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [vidSpesial, setVidSpesial] = useState();
  const [vidHealth, setVidHealth] = useState();
  const [vidFashion, setVidFashion] = useState();
  const [top, setTop] = useState();

  const morePage = () => {

    let body = {
      catId: 5,
      filter: 1,
      page: currentPage + 1
    }

    fetchMoreVideo(body, (data)=>{

      let currentArr = [];  
      currentArr = vidSpesial;

      let obj = JSON.parse(JSON.stringify(data.listVideo));

      for(let i in obj){
        currentArr.push(obj[i])

      }

      setVidSpesial(currentArr)
      setPage(currentPage+1);
    });
  }

  const init = () => {
   
    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    fetchVideoSpesial(null, (data)=>{
     
      setTop(data.banner)
      setVidSpesial(data.videos.listVideo)
      setVidHealth(data.health)
      setVidFashion(data.fashion)

      setPage(data.videos.page)
      setPageTotal(data.videos.totalPage)

      setLoading(false);
    });
  }

  React.useEffect(() => {

    arr.push(["/","Home"])
    arr.push(["/video-category?cid=5","Video"])
    arr.push(["/video-program","Spesial Program"])

    setCrumb(arr)

    init()

  }, []);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='video'/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="video-wrapper container pt-4 pb-16 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <div className="video-program lg:flex lg:flex-row shadow-md">
                <div className="video-program-img lg:relative lg:w-1/2 lg:order-2">                  
                  <img src={top.img_mobile_url} className="lg:absolute lg:w-full lg:h-full lg:object-cover" alt="" />
                </div>
                <div className="video-program-text px-6 lg:px-12 pt-3 sm:pt-9 pb-6 lg:pb-7 lg:w-1/2 lg:order-1">
                  <div className="video-program-text-sub font-semibold">Pretty Squad x Mega Chintasih</div>
                  {/* <div className="video-program-text-date font-semibold mt-1">-</div> */}
                  <div className="video-program-text-p mt-4">
                    <p dangerouslySetInnerHTML={{ __html: top.description }}></p>
                  </div>
                  <div className="md:flex md:items-center mt-6">
                   <button className="video-gen-btn flex items-center justify-center xs:w-full sm:px-6"><a href={"/video-play/"+vidSpesial[0].video_id}><span className="font-semibold mr-2">Mulai Nonton</span></a><img src="https://s.tykcdn.com/assets/pretty_squad/icon/play_icon.svg" alt="" /></button>
                    <BtnShare sharelink={"/video-program/"}/>
                  </div>
                </div>
              </div>
              <div className="px-5 md:px-6 mt-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-9 gap-y-14">
                  {vidSpesial?.map((v) => (
                      <VideoItem key={"sp"+v.video_id} type="premium" content={v.description} cat={v.categories}  id={v.video_id} total_like={v.total_like} thumb={v.thumb_url} title={v.title} time={v.dateStr} duration={v.durationStr}/>
                  ))}
                </div>
                {
                  currentPage < pageTotal &&
                  <button onClick={morePage} className="btn-more mx-auto mt-14 flex items-center justify-center px-1 py-2 w-full">More Video<SvgArrow/></button>
                }
              
              </div>
              <hr className="mt-20"/>
              <SliderContent category="Health & Lifestyle" data={vidHealth}/>
              <hr className="mt-16"/>
              <SliderContent category="Fashion" data={vidFashion}/>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default VideoProgram