import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import ForumItem from '../component/forum-item'
import UserImg from '../component/user-img'
import UserImgName from '../component/user-img-name'
import ListForumTopicButton from '../component/list-forum-topic-button'
import { fetchForumCreate, fetchForumTags, fetchForumTrending, fetchNotif } from '../api_fetch'
import Swal from 'sweetalert2'
import { AVATAR} from '../App';
import { useHookstate } from '@hookstate/core';
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

const ForumTopic = (props) => {

  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true);
  // const [categories, setCategories] = useState();
  const [trending, setTrending] = useState();

  // const [avatar, setAvatar] = useState();
  const myAvatar = useHookstate(AVATAR);  
  const [myname, setMyname] = useState();
  const [tags, setTags] = useState();

  const [called, setCalled] = useState(false);
  const [mytitle, setTitle] = useState();
  const [mydesc, setDesc] = useState();

  const [newNotif, setNewNotif] = useState(false);

  let arr = [];
  const [crumb, setCrumb] = useState([])

  let selectedTags = []

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    if(localStorage.getItem('sessid')===null){
      navigate('/', {
        state: {
          needLogin: true
        }
      });
    }

    // fetchCategory((data)=>{
      // setAvatar(localStorage.getItem('avatar'))
      // setAvatar(myAvatar)
      setMyname(localStorage.getItem('myname'))
      // setCategories(data)


      fetchForumTags((dataTags)=>{
        setTags(dataTags);

        fetchForumTrending((dataTrend)=>{
          setTrending(dataTrend)

          setLoading(false);
        })
      })
      
    // });

    checkNotif();
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  const handleTitle = (e) =>{
    setTitle(e.target.value)
  }

  const handleDesc = (e) => {
    setDesc(e.target.value)
  }

  const handleClick = () =>{
   
    let body = {
          title: mytitle,
          desc: mydesc,
          tags: selectedTags
        }

    if(body.title && body.desc && body.tags){
      fetchForumCreate(body, (data)=>{

        if(data.hasOwnProperty('rc')){
          
          if(data.rc === 0){

            setTitle('')
            setDesc('')

            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Success Submit',
              showConfirmButton: false,
              timer: 1500
            }).then(()=>{
              navigate('/forum-detail/' + data.forum_id);
            })       
              
          }
        }

      })
    }else{
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Harap isi semua field',
        showConfirmButton: false,
        timer: 1500
      })
    }

    
  
  }


  const parentHandleTopic = (value) =>{
    selectedTags = value
    setCalled(false)
  }
  
  React.useEffect(() => {

    arr=[]
    arr.push(["/","Home"])
    arr.push(["/forum-category/1","Forums"])
    arr.push(["/forum-topic","Create New Topic"])

    setCrumb(arr)

    init()
 
   }, []);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='forum' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-14 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <h3 className="font-bold mb-3 sm:mb-6">Sharing Corner</h3>
              <div className="flex gap-24 w-full">
                <div className="w-full lg:w-3/4">
                  <div className="box-topic">
                    <div className="box-topic-head font-semibold px-6 lg:px-8 py-3.5 lg:py-3">Create New Topic</div>
                    <div className="box-topic-body flex gap-3 sm:gap-5 px-3.5 pt-2.5 sm:pt-6 pb-16 sm:pb-12 sm:pl-8 sm:pr-16">
                      <div className="box-topic-body-left hidden sm:block">
                        <UserImg img={myAvatar.get()?myAvatar.get():""}/>
                      </div>
                      <div className="box-topic-body-right">
                        <UserImgName name={myname}/>
                        
                        <div className="new-topic-form mt-4">
                          <h6 className="font-semibold mb-1.5">Judul Topik</h6>
                          <input value={mytitle} maxLength="100" type="text" name="title" className="w-full" placeholder="Tulis topic di sini …" onChange={handleTitle} />
                        </div>
                        <div className="new-topic-form mt-4">
                          <h6 className="font-semibold mb-1.5">Tulis Deskripsi</h6>
                          <textarea value={mydesc} maxLength="400" className="w-full" name="desc" rows="11" placeholder="Tulis deskripsi di sini …" onChange={handleDesc}/>
                        </div>
                        <div className="new-topic-form mt-4">
                          <h6 className="font-semibold mb-1.5">Pilih Hashtag</h6>
                          <ListForumTopicButton tags={tags} parentHandleTopic={parentHandleTopic}/>
                        </div>
                        <button onClick={handleClick} className="btn-fst w-full font-semibold block mx-auto sm:ml-0 mt-16">Add Topic</button>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative hidden lg:block w-1/4">
                  <div className="sticky top-36">
                    <h3 className="font-bold mb-4">Trending Topik :</h3>
                    <div className="trending-topic grid grid-cols-1 gap-5">
                      {trending?.map((trend) => (
                          <ForumItem key={trend.forum_id} img={trend.image_url} id={trend.forum_id} title={trend.title} name={trend.name} time={trend.dateStr} comment={trend.description} tlike={trend.total_like} tcomment={trend.total_comment}/>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default ForumTopic