import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Tags from '../component/tags'
import VideoItemImg from '../component/video-item-img'
import BtnShareInv from '../component/btn-share-inv'

export default function VideoItem(props) {

  let custom;
  let id= props.id;

  if(props.type === 'premium'){
    custom = "/video-play/" + id;
  }else{
    custom = "/article-detail/" + id;
  }

  return (
    <div className="video-item mb-1">
      <VideoItemImg isfree={props.isfree} type={props.type} id={props.id} thumb={props.thumb}/>
      <div className="video-item-bottom shadow-md relative px-4 py-2.5">
        <Tags cat={props.cat}/>
        <Link to={custom}><div className="video-item-title font-medium leading-tight mb-2 line-clamp-2">{props.title}</div></Link>
        <div className="video-item-time mb-2.5"><span className="font-semibold">{props.duration}</span></div>
        <BtnShareInv sharelink={"/video-play/"+props.id}/>
      </div>
    </div>
  )
}