import React, {useState,useRef} from 'react'
import {Wheel} from 'react-custom-roulette'
import {useMediaQuery} from 'react-responsive'
import PopupLuckyWheel from './popup-luckywheel'
import PopupLWPlayed from './popup-lw-played';
import { useNavigate } from "react-router-dom";

const SpinWheel = (props) => {
  const childRef = useRef();
  const childPopRef = useRef();
  const isMobile = useMediaQuery({query: '(min-width: 768px)'})
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([])
  const navigate = useNavigate()

  let prize = [];

  let listPrize = props.list;

  React.useEffect(() => {
    for(let i=0; i<listPrize.length; i++){
      
      if(i%2===0){
        if(listPrize[i].prize_id===10)
          prize.push({option: listPrize[i].prize_name,style:{backgroundColor:'#873CFF',textColor:'#FFEB00'}})
        else
          prize.push({option: listPrize[i].prize_name,style:{backgroundColor:'#873CFF'}})
      }else{
        if(listPrize[i].prize_id===10)
          prize.push({option: listPrize[i].prize_name,style:{backgroundColor:'#398CFF',textColor:'#FFEB00'}})
         else
          prize.push({option: listPrize[i].prize_name,style:{backgroundColor:'#398CFF'}})
      }
        
    }

    setData(prize);
    setLoading(false);
   
  }, []);
  // let data = [
  //   {option:'MYSTERY BOX!',style:{backgroundColor:'#398CFF',textColor:'#FFEB00'}},  // #0
  //   {option:'10 POIN',style:{backgroundColor:'#873CFF'}},                           // #1
  //   {option:'50 POIN',style:{backgroundColor:'#398CFF'}},                           // #2
  //   {option:'KUOTA 50MB',style:{backgroundColor:'#873CFF'}},                        // #3
  //   {option:'50 POIN',style:{backgroundColor:'#398CFF'}},                           // #4
  //   {option:'10 POIN',style:{backgroundColor:'#873CFF'}},                         // #5
  //   {option:'100 POIN',style:{backgroundColor:'#398CFF'}},                          // #6
  //   {option:'KUOTA 100MB',style:{backgroundColor:'#873CFF'}}                        // #7
  // ]

  const [isSpin, setIsSpin] = useState(false);
  const [isPrize, setPrize] = useState();

  const handleSpinClick = () => {
    
    
    if(props.idx===99){
      
      // NEEED LOGIN
      navigate('/', {
        state: {
          needLogin: true
        }
      });

    }else if(props.idx===-1){
      childPopRef.current.handlePopupLWPlayed()

    }else if(props.idx===-2){

      // NEEED SUBS
      navigate('/profile', {
        state: {
          needSubs: true
        }
      });
    }else{
      // IF ALREADY PLAYED TODAY SET isSpin TRUE
      if(isSpin){
        childPopRef.current.handlePopupLWPlayed()
      }else{
        setIsSpin(true);
        setPrize(props.idx);//set hadiah by index START FROM 0
      }
    }
   
   
    
  }

  const PointerProps = {
    src:'https://s.tykcdn.com/assets/pretty_squad/luckywheel/pointer.svg',
    style:{width:isMobile ? '36.5%' : '33%',height:isMobile ? '36.5%' : '33%'}
  }

  const handleClickPopupLuckyWheel = () => {
    childRef.current.handlePopupLuckyWheel();
    setIsSpin(true);
  };

  if(isLoading)
    return (
      <></>
    )

  return (
    <>
    <div className="wheel-circle">
      <Wheel
        data={data}
        mustStartSpinning={isSpin}
        prizeNumber={isPrize}
        onStopSpinning={() => {handleClickPopupLuckyWheel()}}
        textColors={['#FFF']}
        outerBorderColor={'#FFF'}
        outerBorderWidth={11}
        radiusLineWidth={0}
        fontSize={isMobile ? 16 : 20}
        spinDuration={.8}
        pointerProps={PointerProps}
      />
      <div className="wheel-circle-inner">
        <button onClick={handleSpinClick}></button>
      </div>
    </div>
    <PopupLuckyWheel ref={childRef} isPrize={isPrize} data={data}/>
    <PopupLWPlayed ref={childPopRef}/>
    </>
  )
}

export default SpinWheel