import React, {useState} from 'react'
import UserImg from '../component/user-img'
import UserImgNameTime from '../component/user-img-name-time'
import CommentChild from './comment-child'
import { fetchForumReply, fetchLike } from '../api_fetch'
import { useNavigate } from "react-router-dom";

const Comment = (props) => {
  const navigate = useNavigate();

  const [rowText, setRowText] = useState(1)
  const [isReply, setReply] = useState(false);
  const [childCommentReply, setChildCommentReply] = useState()

  const FocusTextarea = () => {
    setRowText(4)
  }

  const clickReply = () =>{
    setReply(true)
  }


  const likey = (type, flagLike) => {

    let param;

    if(flagLike === 1)
      flagLike=0;
    else
      flagLike=1
    
    if(type ==='forum')
      param = {
        forum_id : props.fid,
        liked : flagLike
      }
    else
      param = {
        forum_id : props.fid,
        comment_id : props.id,
        liked : flagLike
      }  

    fetchLike(param, (data)=>{
      props.needRefresh()
    });

    
  }

  const handleChildComment = (e) => {
      setChildCommentReply(e.target.value)
  }

  const replyComment = (commentId) => {
    let param = {
      forum_id:props.fid,
      parent_comment_id:commentId,
      comment: childCommentReply
    }

    if(localStorage.getItem("sessid")===null){
      navigate('/', {
        state: {
          needLogin: true
        }
      });
    }else{     
      fetchForumReply(param, (data)=> {
        setChildCommentReply('')
        props.needRefresh()
      })
    }
  }

  return (
    <div className="comment-item-wrapper flex gap-3 sm:gap-5" id={props.id}>
      <div className="box-topic-body-left hidden sm:block">
        <UserImg img={props.img}/>
      </div>
      <div className="box-topic-body-right forum-detail-item-right">
        <UserImgNameTime time={props.time} name={props.name}/>
        <div className="forum-detail-item-cmt xs:ml-auto pb-5">
          <p>{props.comment}</p>
          <div className="mt-3.5">
            <span className="forum-detail-like forum-item-body-like-right flex items-center">
              <span onClick={()=>likey('comment',props.liked)}><div className={props.liked? "flex items-center liked" : "flex items-center"}><span></span><span>{props.tlike}</span></div></span>
              <div className="flex items-center ml-3 lg:ml-5"><span></span><span>{props.tcomment}</span></div>
              <div className="ml-1.5 lg ml-2.5"><button className="btn-reply font-medium px-2" onClick={clickReply}>Reply</button></div>
            </span>
          </div>
        </div>
        <div className="forum-detail-item-cmt xs:ml-auto flex gap-2">
          {
            props.child?  <span className="comment-reply-left relative"><div className="connect-dot"></div></span> : ""
          }
        
          <span className="comment-reply-right">
          {
            isReply &&
            <div className="comment-reply-item">
              <div className="reply-child flex gap-2">
                <span><textarea value={childCommentReply} maxLength="400" className="w-full" rows={rowText} onFocus={FocusTextarea} placeholder="Tulis komentar di sini …" 
                onChange={handleChildComment}/></span>
                <span className="flex items-start">
                  <button onClick={()=>replyComment(props.id)} className="btn-reply-child relative">
                    <span className="absolute abs-center w-full h-full"></span>
                  </button>
                </span>
              </div>
            </div>
          }

            {props.child?.map((chi,index) => (
                <CommentChild key={'com'+index+chi.comment} img={chi.image_url} time={chi.dateStr} comment={chi.comment} name={chi.name}/>
            ))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Comment