import React, {useState,forwardRef,useImperativeHandle} from 'react'
import {motion,AnimatePresence} from "framer-motion"

const PopupLuckyWheel = forwardRef((props,ref) => {
  useImperativeHandle(ref, () => ({
    handlePopupLuckyWheel
  }));

  const [isPopupLuckyWheel, setPopupLuckyWheel] = useState(false);
  const [isPopupLW, setPopupLW] = useState();

  let body = document.body;
  const handlePopupLuckyWheel = () => {
    setPopupLuckyWheel(!isPopupLuckyWheel);
    
    if(isPopupLuckyWheel === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }

    if(props.isPrize===0){
      setPopupLW(1);
    }else if(props.isPrize===1||props.isPrize===2||props.isPrize===4||props.isPrize===5||props.isPrize===6){
      setPopupLW(2);
    }else if(props.isPrize===3||props.isPrize===7){
      setPopupLW(3);
    }
  }

  const popup = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupbackdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupcontent = {
    hidden: { scale: 0 },
    show: { scale: 1, transition: { type: 'spring', damping: 20, stiffness: 500 } },
    exit: { scale: 0, transition: { duration: .15} }
  }

  return (
    <>
    <AnimatePresence>
    {isPopupLuckyWheel && (<motion.div className="popup popup-lucky fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
      variants={popup}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      {isPopupLuckyWheel && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={handlePopupLuckyWheel}
        variants={popupbackdrop}
        initial="hidden"
        animate="show"
        exit="exit"
      ></motion.div>)}
      {isPopupLuckyWheel && (<motion.div className="popup-content relative mx-auto"
        variants={popupcontent}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <div className="popup-lucky-box relative">
          <div className="popup-lucky-gift absolute">
            <div className="popup-lucky-gift-img">
              {isPopupLW === 1 ? <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/popup/mystery.png" alt="Mystery Box" /> : ''}
              {isPopupLW === 2 ? <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/popup/poin.png" alt="Poin" /> : ''}
              {isPopupLW === 3 ? <img src="https://s.tykcdn.com/assets/pretty_squad/luckywheel/lw/popup/kuota.png" alt="Kuota" /> : ''}
            </div>
          </div>
          <div className="popup-lucky-detail absolute text-center">
            <h3>Selamat! Kamu menang<br/>{props.data[props.isPrize].option}</h3>
            <h4>Kuota akan dikirimkan<br/>dalam 7x24 jam</h4>
            <button onClick={handlePopupLuckyWheel}>OKE</button>
          </div>
        </div>
      </motion.div>)}
    </motion.div>)}
    </AnimatePresence>
    </>
  )
});

export default PopupLuckyWheel;