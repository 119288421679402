import React, {useState, useEffect} from 'react'
import Breadcrumb from '../component/breadcrumb'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'
import { fetchNotif } from '../api_fetch'

const Customer = () => {

  const [newNotif, setNewNotif] = useState(false);

  let arr = [];
  const [crumb, setCrumb] = useState([])

  useEffect(() => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    arr=[]
    arr.push(["/","Home"])
    arr.push(["/customer","Layanan Pelanggan"])

    setCrumb(arr)

    checkNotif()
  }, []);

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content"></div>
          <section className="container pt-4 pb-14 lg:pt-12">
            <Breadcrumb list={crumb}/>
            <div className="wrapper-text relative px-5 sm:px-16 pt-8 pb-10 sm:pb-20">
              <div className="wrapper-text-header sticky sm:relative sm:w-auto -left-5 sm:left-auto -top-8 sm:top-auto mb-3 px-5 sm:px-0 py-2 sm:py-0">
                <h6>Layanan Pelanggan</h6>
              </div>
              <div className="cs-buttons mt-5 sm:mt-14">
                <a href="tel:+622152971540"><button><img src="https://s.tykcdn.com/assets/pretty_squad/icon/text_phone.svg" alt="Phone" /><span>Customer Service (021)52971540</span></button></a>
                <a href="https://www.instagram.com/prettysquad_id/"><button><img src="https://s.tykcdn.com/assets/pretty_squad/icon/text_ig.svg" alt="Instagram" /><span>Instagram @prettysquad_id</span></button></a>
                <a href="mailto:iamprettysquad@gmail.com"><button><img src="https://s.tykcdn.com/assets/pretty_squad/icon/text_email.svg" alt="Email" /><span>Email iamprettysquad@gmail.com</span></button></a>
              </div>
              <div className="cs-address text-left sm:text-center mt-9 sm:mt-16">
                <b>PT.TRIYAKOM</b>
                <p>Gedung Menara Jamsostek Lt.24 <br className="block sm:hidden"/>Menara Utara Suite TA-2403,
                  <br/>Jl Jendral Gatot Subroto No.38 Jakarta Selatan – 12710
                </p>
              </div>
            </div>
          </section>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default Customer