import React, {useRef,useEffect, useState} from 'react'
import Breadcrumb from '../component/breadcrumb'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'

import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'
import { fetchNotif } from '../api_fetch'

const Terms = () => {
  const buttonRef = useRef();

  const [newNotif, setNewNotif] = useState(false);


  let arr = [];
  const [crumb, setCrumb] = useState([]);

  const handleScrollToTop = () => {
    const scrollToTop = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > 0) {
        window.scrollTo(0, scrollTop - scrollTop / 8);
        requestAnimationFrame(scrollToTop);
      }
    };

    scrollToTop();
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 100) {
      buttonRef.current.classList.add('show');
    } else {
      buttonRef.current.classList.remove('show');
    }
  };


  useEffect(() => {
   
    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    arr=[]
    arr.push(["/","Home"])
    arr.push(["/terms","Syarat & Ketentuan"])

    setCrumb(arr);

    checkNotif();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content"></div>
          <section className="container pt-4 pb-14 lg:pt-12">
            <Breadcrumb list={crumb}/>
            <div className="wrapper-text relative px-5 sm:px-16 pt-8 pb-10 sm:pb-20">
              <div className="wrapper-text-header sticky sm:relative sm:w-auto -left-5 sm:left-auto -top-8 sm:top-auto mb-3 px-5 sm:px-0 py-2 sm:py-0">
                <h6>Syarat dan Ketentuan</h6>
              </div>
              <ul className="">
                <li>
                  <b>Tentang Syarat dan Ketentuan</b>
                  <ul className="list-disc pl-7">
                    <li>Pretty Squad menyediakan bermacam-macam fitur untuk para beauty ehthusiast dan membagikan tips dalam kecantikan dan lifestyle.</li>
                    <li>Dengan mengakses situs kami maka user sudah menyetujui segala syarat dan ketentuan yang berlaku.</li>
                    <li>Pretty Squad dapat sewaktu-waktu mengubah syarat dan ketentuan tanpa ada pemberitahuan sebelumnya.</li>
                    <li>Dengan terus mengakses situs kami maka Anda telah setuju mengenai perubahan syarat dan ketentuan yang berlaku.</li>
                    <li>Jika ditemukan terdapat aktivitas manipulasi konten, fitur maupun bentuk manipulasi lainnya di Pretty Squad maka, kami berhak untuk membatasi kemampuan Anda dalam mengakses seluruh situs dan/atau menangguhkan dan menghentikan akun Anda.</li>
                    <li>Pretty Squad mendorong seluruh pengguna atau siapapun yang mencurigai atau mengenali konten dari Pretty Squad yang telah dimanipulasi dan/atau ketentuan layanan kami yang telah dilanggar dengan cara apapun untuk segera memberitahu kami. Kami akan menyelidiki masalah secara menyeluruh dan mengambil tindakan yang sesuai.</li>
                  </ul>
                </li>
                <li>
                  <b>Registrasi dan Data Pengguna</b>
                  <p>Kami mungkin mengharuskan Anda untuk registrasi atau memberikan informasi nomor ponsel dan email Anda untuk menggunakan layanan situs kami. Dengan registrasi pada layanan Pretty Squad maka, Anda telah setuju dengan seluruh kebijakan privasi beserta syarat ketentuan kami.</p>
                </li>
                <li>
                  <b>Mekanisme Berlangganan</b>
                  <p>Anda setuju dan memahami bahwa untuk registrasi pada layanan Pretty Squad Anda akan dikenakan biaya sebesar RP 1500/hari (belum termasuk pajak 11%) yang dibayarkan melalui pulsa serta akan otomatis diperpanjang/diperbaharui hingga Anda mengakhiri keanggotaan di Pretty Squad. Anda bersedia untuk dikirimkan SMS info layanan dan promo secara berkala serta memahami bahwa akses internet dengan menggunakan paket data atau kuota operator untuk mengakses layanan ini adalah diluar biaya berlangganan atau dikenakan biaya tambahan sesuai tarif internet yang berlaku di masing-masing operator.</p>
                </li>
                <li>
                  <b>Perubahan Harga</b>
                  <p>Saat proses berlangganan, Anda akan dikenakan biaya awal sebesar RP 1500/hari (belum termasuk pajak 11%). Pretty Squad dapat sewaktu-waktu melakukan perubahan harga di kemudian hari.</p>
                </li>
                <li>
                  <b>Penghentian Layanan</b>
                  <p>Anda setuju bahwa Pretty Squad mungkin sewaktu-waktu atas kebijakan sendiri, menangguhkan akun Anda dengan atau tanpa alasan pemberitahuan sebelumnya.</p>
                </li>
                <li>
                  <b>Hak Kekayaan Intelektual, Lisensi Untuk Konten, dan Hak Cipta</b>
                  <ul className="list-disc pl-7">
                    <li>PT Triyakom adalah pemilik tunggal atau pemegang lisensi yang sah atas semua hak pada situs dan konten yang dibuat di situs Pretty Squad. Seluruh konten yang dimuat dalam situs Pretty Squad termasuk kekayaan intelektual, dilindungi oleh undang-undang hak cipta dan hukum yang berlaku yang melindungi kekayaan intelektual.</li>
                    <li>Anda memahami bahwa dengan berpartisipasi dalam situs Pretty Squad maka, informasi pribadi lainnya akan tersedia untuk semua pengguna situs lainnya, komunitas dan kemungkinan situs pihak ketiga.</li>
                    <li>Anda bertanggungjawab secara penuh atas semua konten yang Anda unggah, posting, tampilkan melalui forum komunitas kami.</li>
                    <li>Anda memahami bahwa pihak Pretty Squad dapat menghapus, mengubah atau memparafasekan tulisan Anda di forum komunitas kami tanpa pemberitahuan dan alasan apapun.</li>
                    <li>Anda bertanggungjawab penuh atas segala aktivitas yang terjadi di bawah nama pengguna nama akun Anda.</li>
                    <li>Anda tidak diperbolehkan membuat atau mengirimkan email atau komentar yang bersifat komentar negatif, SARA, pornografi kepada pengguna Pretty Squad lainnya.</li>
                    <li>Anda tidak diperbolehkan menyebarluaskan data pribadi seseorang di dalam forum Pretty Squad.</li>
                    <li>Anda mengerti bahwa Pretty Squad berhak untuk menghapus ulasan, komentar atau tulisan dalam forum komunitas Pretty Squad dan juga yang berkaitan dengan pelanggaran kebijakan privasi Pretty Squad termasuk pelanggaran hak cipta, tindakan melawan hukum dan pelecehan.</li>
                    <li>Dalam menggunakan fitur layanan Pretty Squad user dilarang untuk mengunggah atau mempergunakan kata-kata, komentar, yang mengandung unsur SARA, diskriminasi, merendahkan atau menyudutkan orang lain, vulgar, bersifat ancaman, atau hal lain yang dianggap tidak sesuai dengan nilai dan norma sosial. Pretty Squad berhak melakukan tindakan yang diperlukan atas pelanggaran ketentuan ini, seperti penghapusan akun atau pemblokiran akun.</li>
                  </ul>
                </li>
              </ul>
              <div className="last-updt">Last Update 29 Maret 2023</div>
              <button ref={buttonRef} className="btn-up hidden sm:block absolute bottom-8 right-6" onClick={handleScrollToTop}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/up.svg" alt="Up" /></button>
            </div>
          </section>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default Terms