import React from 'react'
import Flickity from 'react-flickity-component'
import Tsel from './payment/tsel'
import Isat from './payment/isat'
import Xl from './payment/xl'
import Hti from './payment/hti'
import Sf from './payment/sf'

export default function PaymentTelco() {
  const optionsPayment = {
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: false
  }

  return (
    <div className="mb-4">
      <div className="payment-option-li-sub mb-1.5">Pilih Providermu :</div>
      <Flickity className="carousel carousel-payment block lg:hidden" elementType="div" options={optionsPayment} reloadOnUpdate={true}>
        <div className="carousel-cell">
          <Isat/>
        </div>
        <div className="carousel-cell">
          <Hti/>
        </div>
        <div className="last-slide"></div>
      </Flickity>
    </div>
  )
}