import React, {useState} from 'react'
import { isDesktop, isAndroid, isIOS } from 'react-device-detect'
import {motion, AnimatePresence} from "framer-motion";

const Hti = () => {

  const [isPopupSMS, setPopupSMS] = useState(false);
  let body = document.body;
  const msg = 'Premium Membership'
  const desc = 'Akses premium konten sepuasnya dengan ketik SMS <b>REG</b>&lt;spasi&gt;<b>PRETTYWEB</b> kirim ke <b>99386</b>'

  const popup = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupbackdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupcontent = {
    hidden: { scale: 0 },
    show: { scale: 1, transition: { type: 'spring', damping: 20, stiffness: 500 } },
    exit: { scale: 0, transition: { duration: .15} }
  }

  const action = () => {
    if(isDesktop){
      setPopupSMS(!isPopupSMS);
    }else {
      window.open('sms:99386;?&body=REG%20PRETTYWEB', '_self');
    }

    // setPopupSMS(!isPopupSMS);

    
  }

  const handlePopupSMS = () => {
    setPopupSMS(!isPopupSMS);
    // setBlur(!isBlur);

    if(isPopupSMS === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  return (
    <>
    <button className="w-full" onClick={()=>action()}>
      <div className="profile-join-btn relative">
        <img src="https://s.tykcdn.com/assets/pretty_squad/img/payment/hti.png" className="medium" alt="" />
      </div>
      <div className="profile-join-btn-price relative w-full grid place-items-center font-semibold">Rp 1665/hari</div>
    </button>

    {/* Popup SMS */}
    <AnimatePresence>
      {isPopupSMS && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupSMS && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={()=>handlePopupSMS(0)}
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
        ></motion.div>)}
        {isPopupSMS && (<motion.div className="popup-content popup-content-md relative mx-auto"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={()=>handlePopupSMS(0)} alt="Button close" /></button>
          <div className="h-full overflow-auto p-3 sm:p-9">
            <div className="items-center">
              <div className="popup-profile-redeem-status text-center px-6 py-8 sm:p-10">
                <h3>{msg}</h3>
                <p className="mt-4" dangerouslySetInnerHTML={{ __html: desc }}></p>
                <button className="w-full mt-7" onClick={()=>handlePopupSMS(0)}>OKE</button>
              </div>
            </div>
          </div>
        </motion.div>)}
      </motion.div>)}
      </AnimatePresence>
      {/* End Popup SMS */}
    </>
  )
}

export default Hti