import React, {useState,forwardRef,useImperativeHandle} from 'react'
import {motion,AnimatePresence} from "framer-motion"

const PopupLWPlayed = forwardRef((props,ref) => {
  useImperativeHandle(ref, () => ({
    handlePopupLWPlayed
  }));

  const [isPopupLWPlayed, setPopupLWPlayed] = useState(false);

  let body = document.body;
  const handlePopupLWPlayed = () => {
    setPopupLWPlayed(!isPopupLWPlayed);

    if(isPopupLWPlayed === false){
      body.classList.add("overflow-hidden")
    }else{
      body.classList.remove("overflow-hidden")
    }
  }

  const popup = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupbackdrop = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: .25} }
  }
  const popupcontent = {
    hidden: { scale: 0 },
    show: { scale: 1, transition: { type: 'spring', damping: 20, stiffness: 500 } },
    exit: { scale: 0, transition: { duration: .15} }
  }

  return (
    <>
    <AnimatePresence>
    {isPopupLWPlayed && (<motion.div className="popup fixed flex items-center justify-center flex-col z-40 top-0 bottom-0 left-0 right-0 overflow-hidden"
      variants={popup}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      {isPopupLWPlayed && (<motion.div className="popup-backdrop absolute top-0 bottom-0 left-0 right-0" onClick={handlePopupLWPlayed}
        variants={popupbackdrop}
        initial="hidden"
        animate="show"
        exit="exit"
      ></motion.div>)}
      {isPopupLWPlayed && (<motion.div className="popup-content popup-content-sm relative mx-auto"
        variants={popupcontent}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <button className="btn-close-login absolute right-0"><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" onClick={handlePopupLWPlayed} alt="Button close" /></button>
        <div className="h-full overflow-auto p-3 sm:p-9">
          <div className="items-center">
            <div className="popup-profile-redeem-status text-center px-6 py-8 sm:p-10">
              <h3>Kamu sudah main hari ini</h3>
              <p className="mt-4">Datang lagi besok yah untuk main!</p>
              <button className="w-full mt-7" onClick={handlePopupLWPlayed}npm>OKE</button>
            </div>
          </div>
        </div>
      </motion.div>)}
    </motion.div>)}
    </AnimatePresence>
    </>
  )
});

export default PopupLWPlayed;