import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import ArticleList from '../component/article-list'
import SvgArrow from '../component/svg-arrow'
import BtnShare from '../component/btn-share'

import { useParams } from "react-router-dom";
import { fetchArticleDetail, fetchNotif } from '../api_fetch'
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

export default function ArticleDetail() {

  const { id } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState();
  const [other, setOther] = useState();

  const [newNotif, setNewNotif] = useState(false);

  let arr = [];
  const [crumb, setCrumb] = useState([])

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
    
    arr=[]
    arr.push(["/","Home"])
    arr.push(["/article/"+1,"Article"])
    arr.push(["/article-detail/"+id,"Berita"])

    setCrumb(arr)

    const param = {
      articleId : id
    }

    fetchArticleDetail(param, (data)=>{
      setDetail(data.detail)
      setOther(data.otherArticles)
      setLoading(false);
    });

    checkNotif();
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

  React.useEffect(() => {
    init()
   }, [id]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='article' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-16 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <div className="block sm:flex gap-16">
                <div className="article-detail-top w-full sm:w-7/12 sm:pt-6 sm:pb-8">
                  <div className="article-detail-head hidden sm:block sm:px-12 pb-6">
                    <div className="h2_2 font-semibold">{detail.title}</div>
                    <div className="article-detail-time mt-3">{detail.dateStr}</div>
                  </div>
                  <div className="article-detail-body pb-12 sm:pb-3 sm:pt-7 sm:px-12">
                    <img loading="lazy" src={detail.image_url} alt="" />
                    <div className="article-src mt-1.5">Sumber : {detail.source}</div>
                    <div className="article-detail-head block sm:hidden mt-4">
                      <h4 className="font-semibold">{detail.title}</h4>
                      <div className="article-detail-time mt-3">{detail.dateStr}</div>
                    </div>
                    <div className="article-detail-text mt-5">
                      <p className="ck-content" dangerouslySetInnerHTML={{ __html: detail.content }}/>
                    </div>
                    {/* <button className="btn-share-article flex items-center justify-center w-full mt-9"><div></div>Share to</button> */}
                    <BtnShare className="mt-9" sharelink={"/article-detail/"+id}/>
                  </div>
                </div>
                <hr className="hr-no-margin block sm:hidden"/>
                <div className="relative w-full sm:w-5/12">
                  <div className="article-detail-btm sticky top-36 pt-4 sm:pt-0 pb-0 sm:py-4">
                    <h4 className="font-semibold mb-6">Article lainnya</h4>
                    <ul>

                      {other?.map((ot) => (
                          <ArticleList key={ot.article_id} title={ot.title} id={ot.article_id} time={ot.dateStr} content={ot.content} image={ot.thumb_url} />
                      ))}
                    </ul>
                    <Link to="/article/1" className="sm:hidden"><button className="btn-more mx-auto mt-14 flex items-center justify-center px-1 py-2 w-full">More Article<SvgArrow/></button></Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}