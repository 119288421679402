import React, {useState, useEffect} from 'react'
import Flickity from 'react-flickity-component'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import VideoItem from '../component/video-item'
import SvgArrow from '../component/svg-arrow'
import SliderContent from '../component/slider-content'
import PopularVideo from '../component/popular-video'
import { useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { fetchVideoCategory, fetchMoreVideo, fetchNotif } from '../api_fetch'
import Filter from '../component/filter'

import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'


const VideoCategory = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  let { search } = useLocation();

  const [newNotif, setNewNotif] = useState(false);

  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState(parseInt(searchParams.get('cid')))
  let { cid } = parseInt(searchParams.get('cid')) 

  const [isLoading, setLoading] = useState(true);
  const [recvid, setVideoRec] = useState();
  const [catvid, setVideoCategory] = useState();
  const [popvid, setVideoPopular] = useState();
  const [contvid, setVideoContinue] = useState();
  const [categories, setCategories] = useState();
  const [currentPage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [sort, setSort] = useState(0);

  let arr = [];
  const [crumb, setCrumb] = useState([])
  
  const optionsPopularVid = {
    freeScroll: false,
    contain: true,
    prevNextButtons: false,
    pageDots: true,
    wrapAround: true
  }

  const parentHandleChange = (childValue) => {
    setSort(childValue)
  }

 

  const morePage = () => {

    let body = {
      catId: openTab,
      filter:sort,
      page: currentPage + 1
    }

    fetchMoreVideo(body, (data)=>{

      let currentArr = [];  
      currentArr = catvid;

      let obj = JSON.parse(JSON.stringify(data.listVideo));

      for(let i in obj){
        currentArr.push(obj[i])

      }

      setVideoCategory(currentArr)
      setPage(currentPage+1);
    });
  }

  const clickTab = (idx) =>{
    navigate("/video-category?cid="+idx);
  }

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
   
    const s = new URLSearchParams(search)
    let c = s.get('cid')

    setOpenTab(parseInt(c))

    const param = {
      catId:openTab,
      filter:sort
    }

    fetchVideoCategory(param, (data)=>{

      setCategories(data.category)
      setVideoRec(data.rec)
      setVideoCategory(data.videos.listVideo)
      setVideoPopular(data.popular)
      setVideoContinue(data.continue_watching)

      setPage(data.videos.page)
      setPageTotal(data.videos.totalPage)

      setLoading(false);
    });

    checkNotif();
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }

   React.useEffect(() => {

    const s = new URLSearchParams(search)
    let c = s.get('cid')

    window.scrollTo(0, 0);

    arr.push(["/","Home"])
    arr.push(["/video-category?cid="+c,"Video"])

    setCrumb(arr)

    init()
  }, [openTab, sort, search]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='video' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="video-wrapper container pt-4 pb-16 lg:pt-12">
              <Breadcrumb list={crumb}/>
              <div className="flex items-center justify-between mb-6">
                <span><h3 className="font-bold">Pilih Kategori</h3></span>
                <span><Filter parentHandleChange={parentHandleChange}/></span>
              </div>             

              <div className="video-tab-head article-tab-head grid grid-cols-5 items-stretch gap-3 mb-9">
                {categories?.map((cat) => (
                  
                  <a key={'top' + cat.category_id} id={cat.title.replaceAll(' ','')+"Tab"} className={"nav-tab flex items-center justify-center w-full " + (openTab === cat.category_id ? "active" : "")}
                  onClick={e => {
                    e.preventDefault();
                    clickTab(cat.category_id);
                  }}
                  data-toggle="tab" href={"#"+cat.title} role="tablist">
                  <div className="flex flex-col lg:flex-row lg:w-full items-center sm:justify-center text-center py-1"><span className="lg:mr-2.5 mb-2.5 lg:mb-0"></span>{cat.title}</div></a>
                ))}
              </div>

              <div className="px-5 md:px-6 mt-12">

                {categories?.map((cat) => (
                    <div className={openTab === cat.category_id ? "block" : "hidden"} key={'list'+cat.category_id} id={cat.title.replaceAll(' ','')}>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-9 gap-y-14">
                          {catvid?.map((c) => (
                            <VideoItem type="premium" key={"cat"+c.video_id} id={c.video_id} isfree={c.flag_free}  thumb={c.thumb_url} cat={c.categories} title={c.title} time={c.dateStr} duration={c.durationStr}/>
                        ))}
                      </div>
                    </div>
                  ))}
                   {
                    currentPage < pageTotal &&
                    <button onClick={morePage} className="btn-more mx-auto mt-14 flex items-center justify-center px-1 py-2 w-full">More Video<SvgArrow/></button>
                  }
              </div>
                          
              <div className="hidden lg:block">
                <hr className="mt-16 mb-14"/>
                <h3 className="font-bold mb-4">Popular Video!</h3>
                <Flickity className="carousel carousel-popular-vid" elementType="div" options={optionsPopularVid}>
                  {popvid?.map((pop) => (
                    <div key={"pop"+pop.video_id} className="carousel-cell">
                      <PopularVideo type="premium" isfree={pop.flag_free} content={pop.description} cat={pop.categories}  id={pop.video_id} total_like={pop.total_like} thumb={pop.thumb_url} title={pop.title} time={pop.dateStr} duration={pop.durationStr}/>
                    </div>
                  ))}
                </Flickity>
              </div>
              <hr className="mt-20"/>
              <SliderContent category="Continue Watching" data={contvid}/>
              <hr className="mt-16"/>
              <SliderContent category="Rekomendasi Video" data={recvid}/>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}

export default VideoCategory