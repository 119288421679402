import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../component/header'
import FloatingBtn from '../component/floating-btn'
import Footer from '../component/footer'
import Breadcrumb from '../component/breadcrumb'
import ArticleItem from '../component/article-item'
import ArticleList from '../component/article-list'
import Filter from '../component/filter'
import SvgArrow from '../component/svg-arrow'
import { fetchArticle, fetchMorePage, fetchMoreOtherPage, fetchNotif } from '../api_fetch'
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { GlobalData } from '../component/global'

export default function Article() {

  const { fil } = useParams();

  const [openTab, setOpenTab] = useState(1);
  const [openTabChild, setOpenTabChild] = useState(1);

  const [article, setArticle] = useState();
  const [other, setOther] = useState();
  const [categories, setCategories] = useState();

  const [isLoading, setLoading] = useState(true);

  const [sort, setSort] = useState(fil);

  const [currentPage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);

  const [currentOtherPage, setOtherPage] = useState(1);
  const [otherpageTotal, setOtherPageTotal] = useState(1);

  const [newNotif, setNewNotif] = useState(false);

  let arr = [];
  const [crumb, setCrumb] = useState([])

  const init = () => {

    ReactGA.send({hitType:"pageview", page: "" + localStorage.getItem("operator") + window.location.pathname});
  
    let body = {
      categoryId: openTab,
      filterType: sort
    }

    // fetchCategory((data)=>{
    //   setCategories((data.splice(0,4)))
    // })

    fetchArticle(body, (data)=>{
      setArticle(data.articles.listArticle)
      setCategories(data.categories)
      setOther(data.other_articles.listArticle)
      
      setPage(data.articles.page)
      setPageTotal(data.articles.totalPage)

      setOtherPage(data.other_articles.page)
      setOtherPageTotal(data.other_articles.totalPage)

      setLoading(false);
    });

    checkNotif()
  }

  const checkNotif = () => {
    fetchNotif((data)=>{

      setNewNotif(data.newNotif);
    })
  }



  const parentHandleChange = (childValue) => {
    setSort(childValue)
  }

  const morePage = () => {

    let body = {
      categoryId: openTab,
      filterType: sort,
      page: currentPage + 1
    }

    fetchMorePage(body, (data)=>{

      let currentArr = [];  
      currentArr = article;

      let obj = JSON.parse(JSON.stringify(data.listArticle));

      for(let i in obj){
        currentArr.push(obj[i])
      }

      setArticle(currentArr)
      setPage(currentPage+1);
    });
  }

  const moreOtherPage = () => {

    let body = {
      categoryId: openTab,
      filterType: sort,
      page: currentOtherPage + 1
    }

    fetchMoreOtherPage(body, (data)=>{

      let currentArr = [];  
      currentArr = other;

      let obj = JSON.parse(JSON.stringify(data.listArticle));
      
      for(let i in obj){
        currentArr.push(obj[i])

      }

      setOther(currentArr)
      setOtherPage(currentOtherPage+1)
    });
  }
  
  React.useEffect(() => {

    arr=[]
    arr.push(["/","Home"])
    arr.push(["/article/"+openTab,"Articles"])

    setCrumb(arr)

    init()
 
   }, [sort,openTab]);

  if(isLoading)
    return (
      <div className="relative">
        <div className="top absolute top-0 w-full"></div>
        <div className="wrapper relative z-10">
          <Header/>
          <FloatingBtn/>
        </div>
      </div>
    )

  return (
    <div className="relative">
      <div className="top absolute top-0 w-full"></div>
      <div className="wrapper relative z-10">
        <Header type='article' nNotif={newNotif}/>
        <FloatingBtn/>
        <main>
          <div className="content">
            <section className="container pt-4 pb-16 lg:pt-12">
            <Breadcrumb list={crumb}/>
              <div className="article-tab pb-8">
                <div className="flex items-center justify-between">
                  <span><h4 className="font-semibold mb-4 sm:hidden">Beauty Articles</h4></span>
                  {/* <span className="block md:hidden"><Filter/></span> */}
                </div>
                <div className="article-tab-head grid grid-cols-5 items-stretch gap-3 mb-9">
                  {categories?.map((cat) => (
                    <a key={'tab'+cat.category_id} id={cat.title.replaceAll(' ','')+"Tab"} className={"nav-tab flex items-center justify-center w-full " + (openTab === cat.category_id ? "active" : "")}
                    onClick={e => {
                      e.preventDefault();
                      setOpenTab(cat.category_id);
                    }}
                    data-toggle="tab" role="tablist">
                    <div className="flex flex-col lg:flex-row lg:w-full items-center sm:justify-center text-center py-1"><span className="lg:mr-2.5 mb-2.5 lg:mb-0"></span>{cat.title}</div></a>
                    
                  ))}
                </div>

                <div className="article-tab-body">
                  {categories?.map((cat) => (
                    <div className={openTab === cat.category_id ? "block" : "hidden"} key={'list'+cat.category_id} id={cat.title.replaceAll(' ','')}>
                      <div>
                        <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-24 gap-y-11">

                          {article?.map((art) => (
                            <ArticleItem key={"art"+art.article_id} title={art.title} id={art.article_id} img={art.thumb_url} time={art.dateStr} content={art.content}/>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                
                {
                  currentPage < pageTotal &&
                  <button onClick={morePage} className="btn-more mx-auto mt-16 flex items-center justify-center px-1 py-2 w-full">More<SvgArrow/></button>
                }
                
              </div>
              <hr className="hr-no-margin hidden sm:block"/>
              <div className="hidden sm:block pt-4 sm:pt-8 pb-0 sm:py-4">
                <div className="flex items-center justify-between mb-12">
                  <span><h4 className="font-semibold">Article lainnya</h4></span>
                  <span><Filter parentHandleChange={parentHandleChange} mode="article"/></span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 lg:gap-x-36 sm:px-8">
                    {other?.map((ot) => (
                        <ArticleList key={'ot'+ot.article_id} id={ot.article_id} title={ot.title} content={ot.content} image={ot.thumb_url}/>
                    ))}
                </div>
                
                {
                  currentOtherPage < otherpageTotal &&
                  <button onClick={moreOtherPage} className="btn-more mx-auto mt-14 flex items-center justify-center px-1 py-2 w-full">More<SvgArrow/></button>
                }
                
              </div>
            </section>
          </div>
      <div className="bottom absolute bottom-0 w-full"></div>
        </main>
        <Footer/>
      </div>
    </div>
  )
}