import React, {useState} from 'react'
import ImageUploading from 'react-images-uploading'
import axios from "axios";
import { AVATAR } from '../App'

export default function ProfileImg(props) {

  const [state, setState] = useState()

  const inputRef = React.useRef(null)
  const [images, setImages] = useState(props.img)

  const triggerClick = () => {
    inputRef.current.click()
  }

  const handleFile = (e) => {
    let file = e.target.files[0];
    let value = URL.createObjectURL(file);
    setState(file);
   
    handleUpload(file)
  }

  const handleUpload = async (f) => {
    await uploadImage(f);
  }

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "ck-pretty": localStorage.getItem("sessid"),
        }
      };
      const API = "upload";
      const HOST = "https://prettysquad.co.id/api";
      const url = `${HOST}/${API}`;
  
      const result = await axios.post(url, formData, config);

      if(result.data.hasOwnProperty('rc')){
        if(result.data.rc === 0){
          // berhasil
          let value = URL.createObjectURL(file);
          setImages(value);
          AVATAR.set(result.data.url)
        }else{
          // gagal
        }
      }else{
        // gagal
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (     
    <div className="profile-user-img relative mx-auto mb-8 sm:mb-0">
      <div className="topic-user-img">
        <img src={images} alt="" />   
      </div>
      <input className='hidden' type="file" accept="image/png, image/jpeg" name="file" onChange={e => handleFile(e)} ref={inputRef}/>
      <button className="absolute" onClick={triggerClick}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/edit_icon.svg" alt="" /></button>
    </div>
  )
}