import React, {useState, useEffect, useRef} from 'react'
import SvgArrow from '../component/svg-arrow'
import {motion,AnimatePresence} from 'framer-motion'
import {Link, useNavigate} from 'react-router-dom'
import PopupLogin from '../component/popup-login'

import {fetchQuest, fetchSubmitAnswer} from "../api_fetch";
import { ISLOGIN } from '../App'
import { useStateWithCallback } from '../useStateWithCallback'
import { useHookstate } from '@hookstate/core'

const Quiz = () => {

  const childRef = useRef();

  const navigate = useNavigate()

  const alreadyLogin = useHookstate(ISLOGIN)

  const handleClickPopup = () => {
    childRef.current.handlePopupLogin()
  }

  let alreadySubmit = false;
  const [param, setParam] = useStateWithCallback([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [isPopupQuiz, setPopupQuiz] = useState(false);
  const [isQuizBlock, setQuizBlock] = useState(false);
  const [listQuest, setQuest] = useState(); 
  const [isLoading, setLoading] = useState(true);

  const [countTrue, setCount] = useState(0);

  useEffect(() => {

    fetchQuest((data)=>{

      if(data.hasOwnProperty('rc')){
        // error something
      }else{
        setQuest(data)
        setLoading(false);
      }
    });

  }, []);

  const handleOptionSelect = (event) => {

    if(alreadyLogin.get()!==null){

      const selectedAnswer = event.currentTarget.dataset.optionValue;
      const answer = {
        quest_id: Number(event.currentTarget.dataset.questionId),
        answer: selectedAnswer,
        correct: selectedAnswer === listQuest[currentQuestionIndex].answer,
      };
     
      setParam([...param, answer],(prevValue, newValue)=>{

        if(newValue.length === 5 && alreadySubmit===false){
          alreadySubmit=true
          submitAnswer(newValue);
        }
        
      });
      setUserAnswers([...userAnswers,answer]);

      const currentButton = event.currentTarget;
      if(answer.correct){
        currentButton.classList.add('correct');
        setCount(countTrue+1)
      }else{
        currentButton.classList.add('wrong');
        const correctButton = currentButton.parentElement.querySelector(`button[data-option-value='${listQuest[currentQuestionIndex].answer}']`);
        if(correctButton){
          correctButton.classList.add('correct');
        }
      }
      if(currentQuestionIndex === listQuest.length - 1){
        if(isPopupQuiz===false){          
          setTimeout(()=>{
            setPopupQuiz(true);
          },1000);
        }
      }else{
        setTimeout(()=>{
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        },1300);
      }
    }else{
      handleClickPopup()
    }

  };

  const handlePopupQuiz = () => {
    if(isPopupQuiz===false){
      setPopupQuiz(true);
    }else{
      setPopupQuiz(false);
      setQuizBlock(true);
    }
  };

  const submitAnswer = (final) => {

    fetchSubmitAnswer(final,(data) =>{
      
      if(data.hasOwnProperty('rc')){
        if(data.rc === -1){
          setPopupQuiz(true);
          setQuizBlock(true);
        }

        if(data.rc === -2){
          navigate('/profile', {
            state: {
              needSubs: true
            }
          });
        }
      }
    })
  }
  
  const renderQuestion = (question) => (
    
    <>
    <div className="daily-quiz-inner grid lg:grid-cols-2 grid-cols-1 gap-2.5 sm:gap-5 py-2 sm:py-5 sm:px-14 px-2 sm:px-5" key={question.quest_id}>
      <div className="daily-quiz-question">
        <div className="daily-question-img">
          <img src={question.img} alt="Soal" />
        </div>
        <div className="daily-question-text px-5 sm:px-9 py-2 leading-tight">{question.question}</div>
      </div>
      <div className="daily-quiz-answer px-3 sm:px-5 py-4 sm:py-6">
        {question.options.map((option, index) => {
          const isCorrectAnswer = userAnswers.find(ans => ans.answer === option && ans.correct);
          const isWrongAnswer = userAnswers.find(ans => ans.answer === option && !ans.correct);
          const classes = [''];
          if(isCorrectAnswer){
            classes.push('correct selected');
          }
          if(isWrongAnswer){
            classes.push('wrong');
            if (option === listQuest[currentQuestionIndex].answer) {
              classes.push('correct');
            }
          }
          return (
            <button
              key={index}
              className={classes.join(' ')}
              data-question-id={question.quest_id}
              data-option-value={option}
              onClick={handleOptionSelect}
            >{option}<span>+5</span></button>
            );
        })}
      </div>
    </div>
    </>
  );

  const renderResult = () => (

    
    <AnimatePresence>
      {isPopupQuiz && (<motion.div className="daily-quiz-popup absolute flex items-center justify-center flex-col top-0 bottom-0 left-0 right-0 overflow-hidden"
        variants={popup}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        {isPopupQuiz && (<motion.div className="daily-quiz-popup-backdrop absolute top-0 bottom-0 left-0 right-0"
          variants={popupbackdrop}
          initial="hidden"
          animate="show"
          exit="exit"
          onClick={handlePopupQuiz}
        ></motion.div>)}
        {isPopupQuiz && (<motion.div className="daily-quiz-popup-content relative mx-auto text-center px-20 py-14"
          variants={popupcontent}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <button className="btn-close-login absolute right-0" onClick={handlePopupQuiz}><img src="https://s.tykcdn.com/assets/pretty_squad/icon/close_btn_pink.svg" alt="Close popup" /></button>
          <h3>Selamat kamu mendapatkan {countTrue*5} poin!</h3>
          <p className="mt-8">Ambil hadiahmu disini:</p>
          <Link to="/profile"><button className="btn-more mx-auto mt-3 flex items-center justify-center px-1 py-2 w-full">Lihat hadiah<SvgArrow/></button></Link>
        </motion.div>)}
      </motion.div>)}
    </AnimatePresence>
  );
  
  const popup = {
    hidden: {opacity: 0},
    show: {opacity: 1},
    exit: {opacity: 0, transition: {duration: .25}}
  }
  const popupbackdrop = {
    hidden: {opacity: 0},
    show: {opacity: 1},
    exit: {opacity: 0, transition: {duration: .25}}
  }
  const popupcontent = {
    hidden: {scale: 0},
    show: {scale: 1, transition: {type: 'spring', damping: 20, stiffness: 500}},
    exit: {scale: 0, transition: {duration: .15}}
  }

  


  if(isLoading)
    return (
      <div></div>
    )


  return (
    <>
      <PopupLogin ref={childRef}/>
      <section className="daily-quiz container pt-8 pb-4 lg:py-16">
        <h2 className="font-semibold mb-2">Your daily beauty quiz!</h2>
        <h5>Jawab kuis dibawah ini dan dapatkan hadiahnya!</h5>
        {isQuizBlock === false ?
        <div className="daily-quiz-outer relative p-2.5 sm:p-5 mt-5">
          {renderQuestion(listQuest[currentQuestionIndex])}
          {isPopupQuiz && <>{renderResult()}</>}
        </div>
        :
        <div className="daily-quiz-block mx-auto mt-12 text-center px-5 py-9 sm:p-9">
          <h3 className="font-semibold">Kamu sudah main hari ini</h3>
          <h4>Datang lagi besok yah untuk main!</h4>
          <Link to="/profile"><button className="btn-more mx-auto mt-6 flex items-center justify-center px-1 py-2 w-full">Cek Poin kamu<SvgArrow/></button></Link>
        </div>
        }
      </section>
    </>
    
  )
}

export default Quiz