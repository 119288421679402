import React from 'react'
import {Link} from 'react-router-dom'
import Flickity from 'react-flickity-component'
import '../css/flickity.min.css'

const HomePromo = (props) => {
  const optionsHomePromo = {
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true
  }

  return (
    <section className="home-promo">
      <Flickity className="carousel carousel-home-promo" elementType="div" options={optionsHomePromo}>
        {props.banner?.map((banner) => (
          <div className="carousel-cell">
            <Link to="/video-program">
              <img src={props.type=='web'?banner.img_web_url:banner.img_mobile_url} alt="" loading='lazy'/>
            </Link>
          </div>
        ))}
       
      </Flickity>
    </section>
  )
}

export default HomePromo