import React from 'react'
import {Link} from 'react-router-dom'

export default function Footer() {

  return (
    <footer className="relative">
      <div className="footer-top">
        <div className="footer-top-inner container py-20 xs:px-6 xs:py-8 block lg:flex items-center justify-between">
          <div>Hey, Pretty!<br/>Let's get in touch!</div>
          <ul className="block lg:hidden mt-8">
            <li><Link to="/terms">Syarat dan ketentuan</Link></li>
            <li><Link to="/privacy">Kebijakan Privasi</Link></li>
            <li><Link to="/customer">Layanan Pelanggan</Link></li>
          </ul>
          <img src="https://s.tykcdn.com/assets/pretty_squad/img/triyakom_logo_white.webp" className="hidden lg:block" alt="" />
        </div>
      </div>
      <div className="footer-btm">
        <div className="footer-btm-inner container py-8 xs:px-1 xs:pt-5 xs:pb-3 lg:flex items-center justify-between">
          <img src="https://s.tykcdn.com/assets/pretty_squad/img/triyakom_logo_white.webp" className="block lg:hidden mx-auto" alt="" />
          <div className="text-center mt-3 lg:mt-0">Copyright &copy; {(new Date().getFullYear())} All rights reserved</div>
          <ul className="hidden lg:flex items-center">
            <li><Link to="/terms">Syarat dan ketentuan</Link></li>
            <li><Link to="/privacy">Kebijakan Privasi</Link></li>
            <li><Link to="/customer">Layanan Pelanggan</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}