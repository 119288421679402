import React from 'react'
import UserImg from '../component/user-img'

export default function UserImgNameTime(props) {

  return (
    <div className="new-topic-user flex items-center gap-3 pt-2 pb-1">
      <span className="new-topic-user-img block sm:hidden"><UserImg/></span>
      <span className="new-topic-user-name font-semibold">{props.name}</span>
      <span className="forum-detail-time">{props.time}</span>
    </div>
  )
}