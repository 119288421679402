import React, {useState} from 'react'

const ForumTopicButton = (props) => {
  let [isSelected, setSelected] = useState(false)

  const handleChange = (e,x) =>{
    setSelected(!x)

    props.parentHandleTags([e,!x])
  }

  return (
    <button className={`text ${isSelected ? "active" : ""}`} onClick={() => handleChange(props.id, isSelected)}>{"#"+props.tag}</button>
  )
}

export default ForumTopicButton;